import {
    GridFilterItem,
    GridFilterOperator,
    getGridStringOperators,
    getGridNumericOperators,
    GridFilterInputMultipleValue,
    GridFilterInputValue
} from '@mui/x-data-grid-pro';
import { FilterCheckboxCriteriaEnum, FilterNumberCriteriaEnum, FilterStringCriteriaEnum } from '../../types';

/**
 *  IMPORTANT
 *
 * Remember that when the filter mode of the DataGrid is in server the getApplyFilterFn in below object arrays
 * doesn't take effect since the grid asume the filtering is made in the server
 *
 */

export const getBaseOperators: () => GridFilterOperator[] = () => [
    {
        label: FilterStringCriteriaEnum.isEmpty,
        value: FilterStringCriteriaEnum.isEmpty,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => !params.value;
        }
    },
    {
        label: FilterStringCriteriaEnum.isNotEmpty,
        value: FilterStringCriteriaEnum.isNotEmpty,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => !!params.value;
        }
    }
];

export const getCheckboxOperators: () => GridFilterOperator[] = () => [
    {
        label: FilterCheckboxCriteriaEnum.isChecked,
        value: FilterCheckboxCriteriaEnum.isChecked,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => !params.value;
        }
    },
    {
        label: FilterCheckboxCriteriaEnum.isNotChecked,
        value: FilterCheckboxCriteriaEnum.isNotChecked,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => !!params.value;
        }
    }
];

export const getRecordGridNumericOperators: () => GridFilterOperator[] = () => [
    {
        label: FilterNumberCriteriaEnum.equals,
        value: FilterNumberCriteriaEnum.equals,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => Number(params.value) === Number(filterItem.value);
        },
        InputComponent: getGridNumericOperators()[0].InputComponent,
        InputComponentProps: getGridNumericOperators()[0].InputComponentProps
    },

    {
        label: FilterNumberCriteriaEnum.greaterThan,
        value: FilterNumberCriteriaEnum.greaterThan,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => Number(params.value) > Number(filterItem.value);
        },
        InputComponent: getGridNumericOperators()[0].InputComponent,
        InputComponentProps: getGridNumericOperators()[0].InputComponentProps
    },

    {
        label: FilterNumberCriteriaEnum.greaterThanOrEqual,
        value: FilterNumberCriteriaEnum.greaterThanOrEqual,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => Number(params.value) >= Number(filterItem.value);
        },
        InputComponent: getGridNumericOperators()[0].InputComponent,
        InputComponentProps: getGridNumericOperators()[0].InputComponentProps
    },

    {
        label: FilterNumberCriteriaEnum.isAnyOf,
        value: FilterNumberCriteriaEnum.isAnyOf,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => Number(params.value).toString().includes(Number(filterItem.value).toString());
        },
        InputComponent: GridFilterInputMultipleValue,
        InputComponentProps: getGridNumericOperators()[0].InputComponentProps
    },

    {
        label: FilterNumberCriteriaEnum.lessThan,
        value: FilterNumberCriteriaEnum.lessThan,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => Number(params.value) < Number(filterItem.value);
        },
        InputComponent: getGridNumericOperators()[0].InputComponent,
        InputComponentProps: getGridNumericOperators()[0].InputComponentProps
    },

    {
        label: FilterNumberCriteriaEnum.lessThanOrEqual,
        value: FilterNumberCriteriaEnum.lessThanOrEqual,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => Number(params.value) <= Number(filterItem.value);
        },
        InputComponent: getGridNumericOperators()[0].InputComponent,
        InputComponentProps: getGridNumericOperators()[0].InputComponentProps
    },

    {
        label: FilterNumberCriteriaEnum.notEqual,
        value: FilterNumberCriteriaEnum.notEqual,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => Number(params.value) !== Number(filterItem.value);
        },
        InputComponent: getGridNumericOperators()[0].InputComponent,
        InputComponentProps: getGridNumericOperators()[0].InputComponentProps
    }
];

export const getRecordGridStringOperators: () => GridFilterOperator[] = () => [
    {
        label: FilterStringCriteriaEnum.equals,
        value: FilterStringCriteriaEnum.equals,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => params.value === filterItem.value;
        },
        InputComponent: GridFilterInputValue,
        InputComponentProps: getGridStringOperators()[0].InputComponentProps
    },

    {
        label: FilterStringCriteriaEnum.contains,
        value: FilterStringCriteriaEnum.contains,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => params.value.includes(filterItem.value);
        },
        InputComponent: GridFilterInputValue,
        InputComponentProps: getGridStringOperators()[0].InputComponentProps
    },

    {
        label: FilterStringCriteriaEnum.endsWith,
        value: FilterStringCriteriaEnum.endsWith,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => params.value.includes(filterItem.value);
        },
        InputComponent: GridFilterInputValue,
        InputComponentProps: getGridStringOperators()[0].InputComponentProps
    },

    {
        label: FilterStringCriteriaEnum.isAnyOf,
        value: FilterStringCriteriaEnum.isAnyOf,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => params.value.includes(filterItem.value);
        },
        InputComponent: GridFilterInputMultipleValue,
        InputComponentProps: getGridStringOperators()[0].InputComponentProps
    },

    {
        label: FilterStringCriteriaEnum.notEqual,
        value: FilterStringCriteriaEnum.notEqual,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => params.value !== filterItem.value;
        },
        InputComponent: GridFilterInputValue,
        InputComponentProps: getGridStringOperators()[0].InputComponentProps
    },

    {
        label: FilterStringCriteriaEnum.startsWith,
        value: FilterStringCriteriaEnum.startsWith,
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }
            return (params): boolean => params.value.includes(filterItem.value);
        },
        InputComponent: GridFilterInputValue,
        InputComponentProps: getGridStringOperators()[0].InputComponentProps
    }
];
