import { Avatar, Box, Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useTheme } from '@mui/material/styles';
import { DropzoneRootProps } from 'react-dropzone';

interface UploadPdfProps {
    rootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
}

export const UploadPdfBanner = ({ rootProps }: UploadPdfProps) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                // height: 750,
                position: 'relative',
                marginLeft: 10,
                marginRight: 10,
                cursor: 'pointer'
            }}
            {...rootProps({ className: 'dropzone' })}
        >
            <Card>
                <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                            color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                            '&[aria-controls="menu-list-grow"],&:hover': {
                                background: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
                                color: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.secondary.light
                            }
                        }}
                        color="inherit"
                    >
                        <UploadFileIcon />
                    </Avatar>

                    <CardActions>
                        <Typography>
                            <Button size="small">Click to upload</Button>
                            or drag and drop
                        </Typography>
                    </CardActions>
                    <Typography color="text.secondary" gutterBottom>
                        PDF (max. 3MB)
                    </Typography>
                </CardContent>
            </Card>
        </Box>
    );
};
