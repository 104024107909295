import { Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import React, { SyntheticEvent } from 'react';
import { useSelector } from 'store';

export interface ICreateDialog {
    children: any;
    open: boolean;
    handleClose: () => void;
    onSubmit: (e: SyntheticEvent) => void;
    isEdit: boolean;
    moduleName: string;
    submitButton?: JSX.Element;
}

export const FormTextField = styled(TextField)(({ theme }) => ({
    '& label.Mui-focused': {
        color: '#666666',
        marginBottom: '.5em ',
        '& .required-text': {
            color: '#C10230'
        }
    },
    '& .MuiOutlinedInput-root': {
        backgroundColor: '#fafafa',
        '&.Mui-focused fieldset': {
            borderColor: '#C4C4C4'
        }
    },
    '& .MuiInputBase-input': {
        backgroundColor: '#fafafa'
    },
    '& .Mui-error fieldset': {
        borderColor: `${theme.palette.warning.main} !important`
    }
}));

export const FormActionButton = styled(Button)<ButtonProps>(() => ({
    width: '185px',
    height: '60px',
    fontSize: '0.938rem',
    borderRadius: '20px'
}));

export const FormDialog = ({ children, open, handleClose, onSubmit, isEdit, moduleName, submitButton }: ICreateDialog) => {
    const tenant = useSelector((state) => state.userTenant.tenant);

    return (
        <Dialog open={open} maxWidth="xs" onClose={handleClose} PaperProps={{ sx: { borderRadius: '20px' } }}>
            <form noValidate onSubmit={onSubmit} style={{ overflowY: 'auto', display: 'inline-flex', flexDirection: 'column' }}>
                <DialogTitle id="form-dialog-title">
                    <Typography
                        variant="h4"
                        sx={{ color: '#1C1B1F', fontSize: (theme) => theme.typography.h2.fontSize, fontWeight: 'normal' }}
                    >
                        {isEdit ? 'Edit' : 'Add'} {moduleName}
                    </Typography>
                    {tenant?.name && (
                        <Typography
                            variant="h4"
                            sx={{
                                marginTop: 1,
                                color: '#1C1B1F',
                                fontSize: (theme) => theme.typography.subtitle1.fontSize,
                                fontWeight: 'normal'
                            }}
                        >
                            {tenant.name}
                        </Typography>
                    )}
                </DialogTitle>
                <DialogContent style={{ paddingBottom: 20, paddingTop: 20 }}>{children}</DialogContent>
                <DialogActions>
                    <Grid container justifyContent="space-around">
                        <Grid item xs="auto">
                            <FormActionButton variant="outlined" onClick={handleClose} color="secondary">
                                Cancel
                            </FormActionButton>
                        </Grid>
                        <Grid item xs="auto">
                            {submitButton || (
                                <FormActionButton type="submit" variant="contained" color="secondary" size="small">
                                    {isEdit ? 'Update' : 'Continue'}
                                </FormActionButton>
                            )}
                        </Grid>
                    </Grid>
                </DialogActions>
            </form>
        </Dialog>
    );
};
