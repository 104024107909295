import { gql } from '@apollo/client';

export const MUTATION_SET_MY_DASHBOARD_PREFERENCE = gql`
    mutation Mutation($data: SetMyDashboardPreferenceInput!) {
        setMyDashboardPreference(data: $data) {
            createdAt
            tag
            updatedAt
            dashboard {
                createdAt
                dashboardId
                enabled
                embedType
                expirationTime
                id
                serverUrl
                siteIdentifier
                updatedAt
                environment
            }
        }
    }
`;
