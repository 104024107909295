import dayjs from 'dayjs';
import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_LIST_VALUES } from 'graphql/queries/customLists';
import { useEffect, useMemo } from 'react';
import { FindListValues, FindListValuesVariables, ListValue } from 'views/backoffice/CustomLists/types';
import { IObjectValues } from '../types';
import { Typography } from '@mui/material';
import { GridRowId } from '@mui/x-data-grid';

export type DropdownRenderProps = {
    value: string;
    handleClickObject: (
        field: string,
        id: number,
        objectValuesDefinition: IObjectValues[],
        isEditable: boolean,
        objectDefinitionId: string,
        isListObject?: boolean
    ) => void;
    isObjectEditable: boolean;
    objectField: string;
    rowId: GridRowId;
    listValues?: ListValue[];
    listId?: number;
    isListObject?: boolean;
    objectDefinitionId?: number | string;
};

export const DropdownRender = ({
    listId,
    listValues,
    value,
    isListObject,
    handleClickObject,
    isObjectEditable,
    objectField,
    rowId,
    objectDefinitionId
}: DropdownRenderProps) => {
    const [getListValues, { data: listValuesData }] = useLazyQuery<FindListValues, FindListValuesVariables>(QUERY_GET_LIST_VALUES, {
        variables: { data: { listId: Number(listId) } }
    });

    const values = useMemo(() => {
        if (listValues) return listValues;
        if (listValuesData) return listValuesData.findListValues || [];
        return [] as ListValue[];
    }, [listValues, listValuesData]);

    const selectedOption = values.find((item) => Number(item.id) === Number(value));

    useEffect(() => {
        if (listId && !listValues?.length) getListValues();
    }, [listId, getListValues, listValues?.length]);

    if (isListObject) {
        return (
            <>
                {selectedOption?.value ? (
                    <>
                        <Typography
                            className="gridObjectValue"
                            data-field={objectField}
                            data-id={rowId}
                            data-editable={false}
                            data-listobject
                            data-objdef={JSON.stringify(selectedOption.dynamicObjectValue?.objectValues)}
                            data-objectdefinitionid={objectDefinitionId}
                            onClick={() => {
                                handleClickObject(
                                    objectField,
                                    +rowId,
                                    (selectedOption.dynamicObjectValue?.objectValues ?? []) as IObjectValues[],
                                    false,
                                    String(objectDefinitionId),
                                    true
                                );
                            }}
                            sx={(theme) => ({ color: theme.palette.secondary.main, cursor: 'pointer', textDecoration: 'underline' })}
                        >
                            {(
                                selectedOption.dynamicObjectValue?.objectValues
                                    .filter((item: any) => item.objectProperty.isDisplayable)
                                    .sort((a: any, b: any) => +(a.objectProperty.order || 0) - +(b.objectProperty.order || 0)) || []
                            )
                                .map((item: any) => {
                                    if (item.objectProperty.dataType === 'date') return dayjs(item.value).format('YYYY-MM-DD');
                                    if (item.objectProperty.dataType === 'datetime') return dayjs(item.value).format('YYYY-MM-DD hh:mm a');
                                    return item.value;
                                })
                                .join(', ') || 'No Display Value Setted'}
                        </Typography>
                    </>
                ) : (
                    <></>
                )}
            </>
        );
    }
    return <>{selectedOption?.userValue?.name || selectedOption?.value || ''}</>;
};
