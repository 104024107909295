import { Chip } from '@mui/material';

export type CheckboxGridRenderProps = {
    value: string;
};

export const CheckboxGridRender = ({ value }: CheckboxGridRenderProps) =>
    value === 'true' ? (
        <Chip
            label="Yes"
            size="small"
            sx={(theme) => ({
                background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light + 60,
                color: theme.palette.success.dark
            })}
        />
    ) : (
        <Chip
            label="No"
            size="small"
            sx={(theme) => ({
                background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light + 80,
                color: theme.palette.orange.dark
            })}
        />
    );
