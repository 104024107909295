/* eslint-disable no-nested-ternary */
import { useMutation } from '@apollo/client';
import { QUERY_FIND_LINE_ITEMS } from 'graphql/queries/lineItems';
import { Grid, IconButton, Paper, Stack } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';
import { GridColDef, GridRowModes, GridRowModesModel, useGridApiContext } from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    MUTATION_REGISTER_LINE_ITEM,
    MUTATION_REGISTER_UPDATE_MANY_LINE_ITEMS,
    MUTATION_UPDATE_LINE_ITEM
} from 'graphql/mutations/lineItems';
import GridWithInlineEdit, { GridWithInlineEditRef } from 'ui-component/grids/GridWithInlineEdit';
import { StripedDataGrid } from 'views/backoffice/CustomLists/components';
import { getRenderCell, getRenderEditCellByType, getValueFormatter } from '../utils';
import { IRecordField } from '../types';
import { useCustomListValues } from 'hooks/useCustomListValues';
import { generateLineItemRows, getFieldsAsHeadersFields, getValueFromLineItemsData } from '../LineItems/utils';
import { getListIdsFromHeaders } from '../utils/headerHelpers';
import { useLineItemsData } from '../LineItems/hooks';
import {
    CreateLineItemsVariables,
    CreateOrUpdateManyLineItems,
    CreateOrUpdateManyLineItemsVariables,
    FindLineItemVariables,
    ICreateLineItems,
    IFindLineItems,
    ILineItemHeader,
    ILineItems,
    IUpdateLineItems,
    LineItemsFields,
    UpdateLineItemsVariables
} from '../LineItems/types';

export interface CustomDetailPanelProps {
    isOpen: boolean;
    recordId: number;
    recordTypeId: number;
    headers: ILineItemHeader;
    onActiveEditing: any;
}

// TODO: use LineItemsGrid here
const CustomDetailPanel = ({ isOpen, recordId, recordTypeId, headers, onActiveEditing }: CustomDetailPanelProps) => {
    const gridRef = useRef<GridWithInlineEditRef | null>(null);
    const [editModel, setEditModel] = useState<GridRowModesModel>({});
    const [newRows, setNewRows] = useState<Record<string, any>[]>([]);
    const apiRef = useGridApiContext();
    const [panelWidth, setPanelWidth] = useState(apiRef.current.rootElementRef?.current?.clientWidth || 0);

    const { lineItemHeaders, getLineItems, lineItemsData, loading: loadingData } = useLineItemsData(recordTypeId as number, headers);

    const [createOrUpdate, { loading: loadingLineItemMutation }] = useMutation<
        CreateOrUpdateManyLineItems,
        CreateOrUpdateManyLineItemsVariables
    >(MUTATION_REGISTER_UPDATE_MANY_LINE_ITEMS, {
        update(cache, { data }) {
            const updated = data?.createOrUpdateManyLineItems;
            const allFields = cache.readQuery<IFindLineItems, FindLineItemVariables>({
                query: QUERY_FIND_LINE_ITEMS,
                variables: {
                    data: {
                        recordHeadersIds: [recordId]
                    }
                }
            });
            if (!allFields || !updated) return;
            const allUpdatedLineItems: Record<string, ILineItems> = {};

            for (const item of updated) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { recordHeader, lineItemsByTypeFileds, ...rest } = item;
                allUpdatedLineItems[rest.id] = rest;
            }

            const newsLineItems = updated
                .filter((el) => !allFields.findLineItems.some((item) => +item.id === +el.id))
                .map(({ lineItemsByTypeFileds, recordHeader, ...rest }) => rest);

            const updatedLineItems = allFields.findLineItems.map((item) => {
                const itWasUpdated = allUpdatedLineItems[item.id];
                return itWasUpdated || item;
            });

            cache.writeQuery<IFindLineItems, FindLineItemVariables>({
                query: QUERY_FIND_LINE_ITEMS,
                variables: {
                    data: {
                        recordHeadersIds: [recordId]
                    }
                },
                data: {
                    findLineItems: [...updatedLineItems, ...newsLineItems]
                }
            });
        }
    });

    const [createLineItem, { loading: loadingCreation }] = useMutation<ICreateLineItems, CreateLineItemsVariables>(
        MUTATION_REGISTER_LINE_ITEM,
        {
            update(cache, { data }) {
                const created = data?.createLineItems;
                const allFields = cache.readQuery<IFindLineItems, FindLineItemVariables>({
                    query: QUERY_FIND_LINE_ITEMS,
                    variables: {
                        data: {
                            recordHeadersIds: [recordId]
                        }
                    }
                });
                if (!allFields || !created) return;

                cache.writeQuery<IFindLineItems, FindLineItemVariables>({
                    query: QUERY_FIND_LINE_ITEMS,
                    variables: {
                        data: {
                            recordHeadersIds: [recordId]
                        }
                    },
                    data: {
                        findLineItems: [...allFields.findLineItems, created]
                    }
                });
            }
        }
    );
    const [updateLineItem, { loading: loadingUpdate }] = useMutation<IUpdateLineItems, UpdateLineItemsVariables>(
        MUTATION_UPDATE_LINE_ITEM,
        {
            update(cache, { data }) {
                const updated = data?.updateLineItems;
                const allFields = cache.readQuery<IFindLineItems, FindLineItemVariables>({
                    query: QUERY_FIND_LINE_ITEMS,
                    variables: {
                        data: {
                            recordHeadersIds: [recordId]
                        }
                    }
                });
                if (!allFields || !updated) return;

                cache.writeQuery<IFindLineItems, FindLineItemVariables>({
                    query: QUERY_FIND_LINE_ITEMS,
                    variables: {
                        data: {
                            recordHeadersIds: [recordId]
                        }
                    },
                    data: {
                        findLineItems: allFields.findLineItems.map((el) => (el.id === updated.id ? updated : el))
                    }
                });
            }
        }
    );

    const headerListIds = useMemo(
        () => getListIdsFromHeaders(getFieldsAsHeadersFields(lineItemHeaders?.lineItemsByTypeFileds)),
        [lineItemHeaders?.lineItemsByTypeFileds]
    );

    const { loading: loadingListValues, listValuesByListId } = useCustomListValues(headerListIds);

    const headerList: GridColDef[] = useMemo(() => {
        if (!lineItemHeaders) return [];
        const sortedArr = [...lineItemHeaders.lineItemsByTypeFileds].sort((a, b) => a.order - b.order);

        return sortedArr.map(
            ({ recordAdditionalFields, order }) =>
                ({
                    field: `${recordAdditionalFields.name}-${order}`,
                    headerName: recordAdditionalFields.name,
                    editable: true,
                    valueFormatter: getValueFormatter(recordAdditionalFields.name, recordAdditionalFields.dataType),
                    renderCell: getRenderCell(
                        recordAdditionalFields.name,
                        undefined,
                        undefined,
                        recordAdditionalFields as unknown as IRecordField,
                        recordAdditionalFields.listType?.id ? listValuesByListId[Number(recordAdditionalFields.listType?.id)] : undefined
                    ),
                    renderEditCell: getRenderEditCellByType(
                        recordAdditionalFields.dataType,
                        recordAdditionalFields.listType?.id,
                        recordAdditionalFields.listType?.id ? listValuesByListId[Number(recordAdditionalFields.listType?.id)] : undefined
                    )
                } as GridColDef)
        );
    }, [lineItemHeaders, listValuesByListId]);

    const handleClickAdd = () => {
        gridRef.current?.handleAddClick();
    };

    const handleProcessUpdate = async (newOne: any) => {
        try {
            let rowId: string | number = 0;
            const items = Object.keys(newOne)
                .filter((key) => !['id', 'order'].includes(key))
                .filter((key) => {
                    const newValue = newOne[key];
                    const oldData = getValueFromLineItemsData(
                        newOne.id,
                        lineItemsData?.findLineItems,
                        lineItemHeaders?.lineItemsByTypeFileds,
                        key
                    );

                    // This is needed to get the rowId even in the non created items
                    if (rowId === 0) rowId = oldData?.lineItemsRow?.id || 0;

                    return !oldData || newValue !== oldData.value;
                })
                .map((key) => {
                    const [fieldNameFromUpdated, fieldOrder] = key.split('-');
                    const idFromHeader = Number(
                        lineItemHeaders?.lineItemsByTypeFileds.find(
                            (el) => el.recordAdditionalFields.name === fieldNameFromUpdated && el.order === Number(fieldOrder)
                        )?.id
                    );

                    const oldData = getValueFromLineItemsData(
                        newOne.id,
                        lineItemsData?.findLineItems,
                        lineItemHeaders?.lineItemsByTypeFileds,
                        key
                    );

                    const id = Number(oldData?.id);

                    return id
                        ? ({
                              id,
                              lineItemsByTypeFiledsId: idFromHeader,
                              value: newOne[key]
                          } as LineItemsFields)
                        : newOne[key]
                        ? ({
                              lineItemsByTypeFiledsId: idFromHeader,
                              value: newOne[key]
                          } as LineItemsFields)
                        : null;
                })
                .filter((el) => el !== null);

            if (rowId !== 0) {
                await createOrUpdate({
                    variables: { data: { index: Number(newOne.id), lineItemsFields: items, recordHeaderId: recordId, rowId: +rowId } }
                });
            } else {
                const requests = items.map((item) =>
                    item.id
                        ? updateLineItem({
                              variables: {
                                  data: {
                                      ...item,
                                      id: item.id as number,
                                      index: Number(newOne.id),
                                      recordHeaderId: recordId
                                  }
                              }
                          })
                        : createLineItem({
                              variables: {
                                  data: {
                                      ...item,
                                      index: Number(newOne.id),
                                      recordHeaderId: recordId
                                  }
                              }
                          })
                );
                await Promise.all(requests);
            }
            setNewRows((prev) => prev.map((el) => (el.id === newOne.id ? newOne : el)));
            return newOne;
        } catch (error) {
            console.log('error changing values', error);
        }
        return null;
    };

    const handleProcessCreate = async (newOne: any) => {
        try {
            const index = newRows.slice(-1)[0]?.id + 1 || 1;
            const items = Object.keys(newOne)
                .filter((key) => !['id', 'autogenerate', 'order'].includes(key) && newOne[key])
                .map((key) => {
                    const [fieldNameFromUpdated, fieldOrder] = key.split('-');
                    const lineItemsByTypeFiledsId = Number(
                        lineItemHeaders?.lineItemsByTypeFileds.find(
                            (el) => el.recordAdditionalFields.name === fieldNameFromUpdated && el.order === Number(fieldOrder)
                        )?.id
                    );

                    return {
                        lineItemsByTypeFiledsId,
                        value: newOne[key]
                    } as LineItemsFields;
                });

            await createOrUpdate({
                variables: { data: { index, lineItemsFields: items, recordHeaderId: recordId } }
            });

            setNewRows((prev) => [...prev, { ...newOne, id: index }]);
            return { ...newOne, id: index };
        } catch (error) {
            console.log('error creating new line item', error);
            return null;
        }
    };

    const getLineItemData = useCallback(async () => {
        const { data } = await getLineItems({ fetchPolicy: 'network-only', variables: { data: { recordHeadersIds: [recordId] } } });
        const rows = generateLineItemRows(lineItemHeaders?.lineItemsByTypeFileds, data?.findLineItems);
        setNewRows(rows);
    }, [getLineItems, lineItemHeaders, recordId]);

    const isEditing = useMemo(() => Object.values(editModel).some((value) => value.mode === GridRowModes.Edit), [editModel]);

    useEffect(() => {
        onActiveEditing(isEditing);
    }, [isEditing, onActiveEditing]);

    useEffect(() => {
        if (isOpen) getLineItemData();
    }, [getLineItemData, isOpen]);

    // To resize the panel
    useEffect(() => {
        let observer: null | ResizeObserver = null;

        if (apiRef?.current?.rootElementRef?.current) {
            observer = new ResizeObserver((e) => {
                setPanelWidth(e[0].target.clientWidth);
            });

            observer.observe(apiRef.current.rootElementRef.current);
        }

        return () => {
            if (observer) observer.disconnect();
        };
    }, [apiRef]);

    return (
        <Stack sx={{ display: 'flex', height: '100%', maxWidth: panelWidth || 'inherit' }}>
            <Paper elevation={1} component={Grid} container sx={{ height: '400px', m: '20px', p: '20px', flexGrow: 1 }}>
                <Grid item xs={12} container spacing={1}>
                    <Grid item xs={10} sx={{ height: '360px' }}>
                        <GridWithInlineEdit
                            ref={gridRef}
                            GridComponent={StripedDataGrid}
                            density="compact"
                            onCreate={handleProcessCreate}
                            onUpdate={handleProcessUpdate}
                            loading={loadingLineItemMutation || loadingData || loadingListValues || loadingCreation || loadingUpdate}
                            columns={headerList}
                            fieldToFocus={headerList[0]?.field || ''}
                            initialRows={newRows}
                            onChangeEditModel={setEditModel}
                            disabledReordering
                            disabledCheckboxSelection
                            shouldEdit
                            autosizeColumns
                            pinnedActions
                        />
                    </Grid>
                    <Grid item xs={2} sx={{ maxWidth: '100px !important' }}>
                        <IconButton onClick={handleClickAdd} sx={{ mb: 1 }} color="secondary" aria-label="add new row">
                            <AddCircleOutline />
                        </IconButton>
                    </Grid>
                </Grid>
            </Paper>
        </Stack>
    );
};

export default CustomDetailPanel;
