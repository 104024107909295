import { createSlice } from '@reduxjs/toolkit';
import { DashboardStore } from 'types/dashboard';

// initial state
const initialState: DashboardStore = {
    selectedDashboard: undefined,
    dashboardList: []
};

// ==============================|| SLICE - DASHBOARD ||============================== //

const dashboard = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setDashboardList(state, action) {
            state.dashboardList = action.payload;
        },

        setSelectedDashboard(state, action) {
            state.selectedDashboard = action.payload;
        }
    }
});

export default dashboard.reducer;

export const { setDashboardList, setSelectedDashboard } = dashboard.actions;
