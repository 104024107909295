import { gql } from '@apollo/client';

export const QUERY_GET_MY_DASHBOARD = gql`
    query GetMyDashboards {
        getMyDashboards {
            createdAt
            dashboardId
            enabled
            embedType
            expirationTime
            id
            name
            serverUrl
            siteIdentifier
            updatedAt
            environment
        }
    }
`;

export const QUERY_GET_MY_DASHBOARD_PREFERENCE = gql`
    query GetMyDashboardPreference {
        getMyDashboardPreference {
            createdAt
            tag
            updatedAt
            dashboard {
                createdAt
                dashboardId
                enabled
                embedType
                expirationTime
                id
                name
                serverUrl
                siteIdentifier
                updatedAt
                environment
            }
        }
    }
`;
