import { NavItemType } from 'types';
import { IconTriangleSquareCircle, IconSettings, IconSubtask } from '@tabler/icons';

const poetMenuItems: NavItemType[] = [
    {
        id: 'products',
        title: 'Products',
        type: 'item',
        icon: IconTriangleSquareCircle,
        url: 'products'
    },
    {
        id: 'channels',
        title: 'Channels',
        type: 'item',
        icon: IconSubtask,
        url: 'channels'
    },
    {
        id: 'settings',
        title: 'Settings',
        type: 'item',
        icon: IconSettings,
        url: 'settings'
    }
];

export default poetMenuItems;
