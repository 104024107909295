const colors = {
    light: {
        secondary: {
            '200': '#b4cdfb',
            '800': '#4c7ff3',
            main: '#699bf7',
            light: '#edf3fe',
            dark: '#6193f6'
        },
        primary: {
            '200': '#fde7aa',
            '800': '#f8bc3a',
            main: '#face54',
            light: '#fef9ea',
            dark: '#f9c94d'
        }
    },
    dark: {
        secondary: {
            '200': '#b4cdfb',
            '800': '#4c7ff3',
            main: '#699bf7',
            light: '#edf3fe',
            dark: '#6193f6'
        },
        primary: {
            '200': '#fde7aa',
            '800': '#f8bc3a',
            main: '#face54',
            light: '#fef9ea',
            dark: '#f9c94d'
        }
    }
};

export default colors;
