import { ClassNameMap } from '@mui/styles';
import { GridRowClassNameParams } from '@mui/x-data-grid-pro';
import { IRecordHeaders } from 'ui-component/records/types';

export const getRowCustomClassName =
    (
        classes: ClassNameMap<
            | 'gridOptionsButton'
            | 'evenRow'
            | 'oddRow'
            | 'gridToolbarCustomFont'
            | 'gridToolbarCustomFontBlack'
            | 'paginationItems'
            | 'paginationArrow'
            | 'paginationControl'
        >
    ) =>
    (params: GridRowClassNameParams<IRecordHeaders>) => {
        let className = '';

        if (params.indexRelativeToCurrentPage % 2 !== 0) className = classes.oddRow;
        else className = classes.evenRow;

        // if (!params.row.enabled) className += ` ${classes.disabledRow}`;

        return className;
    };
