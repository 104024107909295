import { GridDensityState } from '@mui/x-data-grid-pro';

export const densityFactors: GridDensityState[] = [
    {
        factor: 1.3,
        value: 'comfortable'
    },
    {
        value: 'compact',
        factor: 0.7
    },
    {
        value: 'standard',
        factor: 1
    }
];

export const getDensityFactor = (selectedFactor: number | null) =>
    selectedFactor ? densityFactors.find(({ factor }) => factor === selectedFactor)?.value || 'standard' : 'standard';
