import { SyntheticEvent } from 'react';

// material-ui
import { Alert, AlertTitle, Button, Fade, Grow, IconButton, Slide, SlideProps, Typography, useTheme } from '@mui/material';

import MuiSnackbar from '@mui/material/Snackbar';

// assets
import CloseIcon from '@mui/icons-material/Close';

import { KeyedObject } from 'types';
import { useDispatch, useSelector } from 'store';
import { closeSnackbar } from 'store/slices/snackbar';
import { isPoetProject } from 'utils';

// animation function
function TransitionSlideLeft(props: SlideProps) {
    return <Slide {...props} direction="left" />;
}

function TransitionSlideUp(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

function TransitionSlideRight(props: SlideProps) {
    return <Slide {...props} direction="right" />;
}

function TransitionSlideDown(props: SlideProps) {
    return <Slide {...props} direction="down" />;
}

function GrowTransition(props: SlideProps) {
    return <Grow {...props} />;
}

// animation options
const animation: KeyedObject = {
    SlideLeft: TransitionSlideLeft,
    SlideUp: TransitionSlideUp,
    SlideRight: TransitionSlideRight,
    SlideDown: TransitionSlideDown,
    Grow: GrowTransition,
    Fade
};

// ==============================|| SNACKBAR ||============================== //

const Snackbar = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const snackbar = useSelector((state) => state.snackbar);
    const { actionButton, alert, message, subtitle, open, transition, variant, cy } = snackbar;
    const anchorOrigin = { ...snackbar.anchorOrigin };

    let snackbarSx = {};
    let timeout = 3000;

    if (alert.color === 'success') {
        timeout = 1000;
    }

    if (String(anchorOrigin.vertical) === 'center') {
        snackbarSx = {
            top: '0 !important',
            height: '100%',
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            pointerEvents: 'none'
        };
        anchorOrigin.vertical = 'top';
        timeout = 1500;
    }

    const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(closeSnackbar());
    };

    const mapBackgroundColors = (severityKey: string) => {
        switch (severityKey) {
            case 'success':
                return '#2E7D32';
            case 'error':
                return '#EF5350';
            case 'warning':
                return '#FF9800';
            case 'info':
                return theme.palette.secondary.main;
            default:
                return '#2E7D32';
        }
    };
    return (
        <>
            {/* default snackbar */}
            {variant === 'default' && (
                <MuiSnackbar
                    anchorOrigin={anchorOrigin}
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message={message}
                    TransitionComponent={animation[transition]}
                    action={
                        <>
                            <Button color="secondary" size="small" onClick={handleClose}>
                                UNDO
                            </Button>
                            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose} sx={{ mt: 0.25 }}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </>
                    }
                />
            )}

            {/* alert snackbar */}
            {variant === 'alert' && (
                <MuiSnackbar
                    TransitionComponent={animation[transition]}
                    anchorOrigin={anchorOrigin}
                    open={open}
                    autoHideDuration={timeout}
                    onClose={handleClose}
                    sx={isPoetProject() ? snackbarSx : {}}
                >
                    <Alert
                        {...(alert.color === 'success' || alert.color === 'warning'
                            ? { severity: alert.color, variant: 'filled' }
                            : { variant: alert.variant, color: alert.color, severity: alert.severity ?? 'info' })}
                        action={
                            <>
                                {actionButton !== false && (
                                    <Button size="small" onClick={handleClose} sx={{ color: 'background.paper' }}>
                                        UNDO
                                    </Button>
                                )}

                                <IconButton sx={{ color: 'background.paper' }} size="small" aria-label="close" onClick={handleClose}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </>
                        }
                        sx={{
                            ...(alert.variant === 'outlined' && {
                                bgcolor: 'background.paper'
                            }),
                            ...(alert.variant === 'filled' && {
                                bgcolor: `${mapBackgroundColors(alert.color || 'success')} !important`,
                                color: '#FFF'
                            }),
                            minWidth: '320px',
                            ...(!isPoetProject() && {
                                color: 'white'
                            })
                        }}
                        data-cy={cy || alert.variant}
                    >
                        <AlertTitle sx={alert.color === 'info' ? { m: '0 !important' } : undefined}>{message}</AlertTitle>
                        {subtitle && <Typography>{subtitle}</Typography>}
                    </Alert>
                </MuiSnackbar>
            )}
        </>
    );
};

export default Snackbar;
