import React, { useMemo, useState } from 'react';
import { Box, Checkbox, Grid, List, ListItem, ListItemAvatar, ListItemText, Paper, TextField } from '@mui/material';
import { DragIndicator, Search } from '@mui/icons-material';
import { DragDropContext, Draggable, DraggableProvided, DropResult, Droppable } from 'react-beautiful-dnd';
import { reorder } from '../utils';

export type SelectedFieldsProps = {
    fieldList: Record<'id' | 'name', string>[];
    disabledFields: Record<'id' | 'name', string>[];
    onChangeSelectedFields: (field: Record<string, any>[]) => void;
};
export const SelectedFields = ({ fieldList, onChangeSelectedFields, disabledFields }: SelectedFieldsProps) => {
    const [searchQuery, setSearchQuery] = useState('');

    const filteredSelectedFields = useMemo(
        () => fieldList.filter((el) => el.name.toLowerCase().includes(searchQuery.toLowerCase())),
        [fieldList, searchQuery]
    );

    const handleRemoveSelected = (field: Record<string, any>) => () => {
        onChangeSelectedFields(fieldList.filter((el) => el.id !== field.id));
    };

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) return;

        const movedItems = reorder<Record<string, any>>(fieldList, result.source.index, result.destination.index);
        onChangeSelectedFields(movedItems);
    };

    return (
        <Grid item xs={6}>
            <Paper sx={{ minHeight: '100%' }}>
                <Box>
                    <ListItem>
                        <ListItemAvatar style={{ width: '20%' }}>
                            <Checkbox checked={!!fieldList.length} onChange={() => onChangeSelectedFields([])} />
                        </ListItemAvatar>
                        <ListItemText style={{ width: '80%' }}>
                            <TextField
                                size="small"
                                margin="dense"
                                variant="outlined"
                                name="selectedSearchQuery"
                                type="search"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                InputProps={{ startAdornment: <Search sx={{ ml: '-5px', mr: 1, color: 'gray' }} /> }}
                                placeholder="Search"
                                hiddenLabel
                                fullWidth
                            />
                        </ListItemText>
                    </ListItem>
                </Box>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided) => (
                            <List {...provided.droppableProps} ref={provided.innerRef}>
                                {filteredSelectedFields.map((field, index) => (
                                    <Draggable key={field.id} draggableId={`q-${field.id}`} index={index}>
                                        {(dragProvided: DraggableProvided) => (
                                            <ListItem sx={{ py: 0 }} ref={dragProvided.innerRef} {...dragProvided.draggableProps}>
                                                <ListItemAvatar {...dragProvided.dragHandleProps} style={{ width: '10%', minWidth: 0 }}>
                                                    <DragIndicator fontSize="small" />
                                                </ListItemAvatar>
                                                <ListItemText style={{ width: '10%' }}>
                                                    <Checkbox
                                                        checked
                                                        disabled={!!disabledFields.find((el) => Number(el.id) === Number(field.id))}
                                                        style={{ marginTop: '-4px', marginLeft: '4px' }}
                                                        onChange={handleRemoveSelected(field)}
                                                    />
                                                    {field.name}
                                                </ListItemText>
                                            </ListItem>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </List>
                        )}
                    </Droppable>
                </DragDropContext>
            </Paper>
        </Grid>
    );
};
