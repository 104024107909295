/* eslint-disable arrow-body-style */
import { AddCircleOutline, Search } from '@mui/icons-material';
import { Grid, OutlinedInput, InputAdornment, IconButton } from '@mui/material';
import React, { ChangeEvent } from 'react';

export type OptionsBannerProps = {
    name?: string;
    searchInput: string;
    onSearchChange: (e: ChangeEvent<HTMLInputElement>) => void;
    showAdd?: boolean;
    onAdd: () => void;
};

export const OptionsBanner = ({ name, searchInput, onSearchChange, onAdd, showAdd }: OptionsBannerProps) => {
    return (
        <Grid container alignItems="center" justifyContent={{ xs: 'center', md: 'flex-start' }} spacing={{ xs: 0, md: 2 }} sx={{ my: 1 }}>
            <Grid item xs={11} md="auto">
                <OutlinedInput
                    fullWidth
                    sx={(theme) => ({
                        width: '300px',
                        borderRadius: '30px',
                        // backgroundColor: theme.palette.secondary.light,
                        color: `${theme.palette.secondary.main} !important`,
                        '&.Mui-focused': {
                            backgroundColor: `${theme.palette.secondary.light} !important`,
                            '& svg.MuiSvgIcon-root > path': {
                                fill: `${theme.palette.secondary.main} !important`
                            }
                        },
                        '& :focus-visible, :focus, :active, .MuiInputBase-root .Mui-focused': {
                            backgroundColor: `${theme.palette.secondary.light} !important`,
                            color: `${theme.palette.secondary.main} !important`,
                            '&::placeholder, svg': {
                                color: `${theme.palette.secondary.main} !important`,
                                opacity: 0.5
                            }
                        },
                        height: '100%',
                        '& > fieldset': {
                            borderRadius: '30px'
                        }
                    })}
                    id="input-quick-search"
                    placeholder={`Find in ${name?.toLowerCase() || ''}`}
                    startAdornment={
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    }
                    size="small"
                    onChange={onSearchChange}
                    value={searchInput}
                />
            </Grid>
            {showAdd && (
                <Grid item xs={1} sx={{ textAlign: { md: 'right' }, ml: { md: 'auto' } }}>
                    <IconButton color="secondary" onClick={onAdd}>
                        <AddCircleOutline />
                    </IconButton>
                </Grid>
            )}
        </Grid>
    );
};
