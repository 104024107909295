import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useQueryParams = (selectedKey?: string): Record<string, string> | string => {
    const { search } = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(search), [search]);

    const [searchParams, setSearchParams] = useState<Record<string, string>>({});

    useEffect(() => {
        for (const [key, value] of queryParams.entries()) {
            setSearchParams((prev) => ({ ...prev, [key]: value }));
        }
    }, [queryParams]);

    return selectedKey ? searchParams[selectedKey] : searchParams;
};
