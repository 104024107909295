import { gql } from '@apollo/client';

export const QUERY_GET_PROBLEM_CODE = gql`
    query FindProblemCodeByRecordType($data: ProblemCodeByRecordTypeFindInput!) {
        findProblemCodeByRecordType(data: $data) {
            id
            name
            recordType {
                id
            }
            enabled
        }
    }
`;
