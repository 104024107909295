import { Search } from '@mui/icons-material';
import { Checkbox, Grid, ListItem, ListItemAvatar, ListItemText, Paper, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React, { useMemo, useState } from 'react';

export type AvailableFieldsProps = {
    selectedAll: boolean;
    onChangeSelectAll: () => void;
    fieldList: Record<'id' | 'name', string>[];
    selectedFieldList: Record<'id' | 'name', string>[];
    onSelectField: (field: Record<'id' | 'name', string>) => (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const AvailableFields = ({ selectedAll, onChangeSelectAll, fieldList, selectedFieldList, onSelectField }: AvailableFieldsProps) => {
    const [searchQuery, setSearchQuery] = useState('');

    const filteredFields = useMemo(
        () => fieldList.filter((el) => el.name.toLowerCase().includes(searchQuery.toLowerCase())),
        [fieldList, searchQuery]
    );
    return (
        <Grid item xs={6}>
            <Paper>
                <Box>
                    <ListItem>
                        <ListItemAvatar style={{ width: '20%' }}>
                            <Checkbox checked={selectedAll} onChange={onChangeSelectAll} />
                        </ListItemAvatar>
                        <ListItemText style={{ width: '80%' }}>
                            <TextField
                                size="small"
                                margin="dense"
                                variant="outlined"
                                name="searchQuery"
                                type="search"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                InputProps={{ startAdornment: <Search sx={{ ml: '-5px', mr: 1, color: 'gray' }} /> }}
                                placeholder="Search"
                                hiddenLabel
                                fullWidth
                            />
                        </ListItemText>
                    </ListItem>
                </Box>
                {filteredFields.map((field) => (
                    <ListItem key={field.id} sx={{ py: 0 }}>
                        <ListItemAvatar style={{ width: '20%' }}>
                            <Checkbox checked={selectedFieldList.map((el) => el.id).includes(field.id)} onChange={onSelectField(field)} />
                        </ListItemAvatar>
                        <ListItemText style={{ width: '80%', textAlign: 'center' }}>{field.name}</ListItemText>
                    </ListItem>
                ))}
            </Paper>
        </Grid>
    );
};
