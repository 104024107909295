import { GridEventListener, MuiEvent } from '@mui/x-data-grid-pro';

export const handleCellFocusOut = (event: MuiEvent) => {
    console.log('event', event);
    event.defaultMuiPrevented = true;
};

export const handleCellKeydown: GridEventListener<'cellKeyDown'> = (_params, event) => {
    event.defaultMuiPrevented = true;
};
