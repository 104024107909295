import { useEffect, useState } from 'react';
import { FindLineItemVariables, IFindAllLineItemHeaders, IFindLineItems, ILineItemHeader } from '../types';
import { QUERY_FIND_ALL_LINE_ITEMS_HEADERS, QUERY_FIND_LINE_ITEMS } from 'graphql/queries/lineItems';
import { useLazyQuery } from '@apollo/client';

export const useLineItemsData = (recordTypeId: number, headers?: ILineItemHeader) => {
    const [lineItemHeaders, setLineItemHeaders] = useState(headers);

    const [getAllHeaders, { loading: loadingLineItems }] = useLazyQuery<IFindAllLineItemHeaders>(QUERY_FIND_ALL_LINE_ITEMS_HEADERS, {
        onCompleted(data) {
            setLineItemHeaders(
                data.findAllLineItemsByType.find((itemData) => {
                    const recordTypesArr = itemData.recordType.map((el) => Number(el.id));
                    return recordTypesArr.includes(recordTypeId as number);
                }) || undefined
            );
        }
    });

    const [getLineItems, { data: lineItemsData, loading: loadingList }] = useLazyQuery<IFindLineItems, FindLineItemVariables>(
        QUERY_FIND_LINE_ITEMS
    );

    const loading = loadingLineItems || loadingList;

    useEffect(() => {
        if (!headers) getAllHeaders();
    }, [getAllHeaders, headers]);

    return { lineItemHeaders, getLineItems, lineItemsData, loading };
};

export default useLineItemsData;
