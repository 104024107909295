import { gql } from '@apollo/client';

export const QUERY_GET_RECORD_STATUS = gql`
    query GetRecordStatusByRecordType {
        getRecordStatusByRecordType {
            enabled
            id
            name
        }
    }
`;

export const QUERY_FIND_RECORD_STATUS = gql`
    query FindRecordStatusByRecordType($data: RecordStatusByRecordTypeFindInput!) {
        findRecordStatusByRecordType(data: $data) {
            enabled
            id
            name
            recordType {
                id
            }
        }
    }
`;
