import { InMemoryCacheConfig } from '@apollo/client';
import { KeySpecifier } from '@apollo/client/cache/inmemory/policies';

export const typePolicies: InMemoryCacheConfig['typePolicies'] = {
    Query: {
        fields: {
            filterAuditLogsForRecord: {
                keyArgs: (args) => {
                    if (!args) return false;
                    return Object.keys(args.data) as KeySpecifier;
                },
                merge(incoming, existing, { args }) {
                    const merged = existing ? { ...existing } : {};

                    const logs = existing?.logs ? [...existing.logs] : [];

                    if (incoming) {
                        if (args) {
                            const { offset = 0 } = args.data.pagination;
                            for (let i = 0; i < incoming.logs.length; i += 1) logs[offset + i] = incoming.logs[i];
                        } else {
                            logs.push(...incoming.logs);
                        }
                    }
                    return { ...merged, logs };
                }
            }
        }
    }
};
