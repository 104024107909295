import { IRecordHeaders } from 'ui-component/records/types';

/**
 * Generate the updated list for list with and without enabled records
 *
 * @param list {IRecordHeaders[]} list of records
 * @param updatedRecord {IRecordHeaders} updated record
 * @param showOnlyEnabledRecords {Boolean} true if list only contains enabled values
 */
export const generateUpdatedList = (list: IRecordHeaders[], updatedRecord: IRecordHeaders, showOnlyEnabledRecords: boolean) => {
    if (!showOnlyEnabledRecords || (showOnlyEnabledRecords && updatedRecord.enabled))
        return list.map((el) => (Number(el.id) === Number(updatedRecord.id) ? updatedRecord : el));

    if (showOnlyEnabledRecords && !updatedRecord.enabled) return list.filter((el) => Number(el.id) !== Number(updatedRecord.id));
    return list;
};
