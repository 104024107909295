// material-ui
// import { useTheme } from '@mui/material/styles';
// import logo from 'assets/images/avanto-logo.png';
//
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 *
 */

import { isPoetProject } from 'utils';

// ==============================|| LOGO SVG ||============================== //

const logoUrl = isPoetProject() ? '/poet-logo-primary.png' : '/NewOrderbahnLogo.svg';
const altText = isPoetProject() ? 'Poet logo' : 'Avanto logo';

const Logo = () => <img src={logoUrl} alt={altText} style={{ margin: 'auto', width: '100%' }} />;

export default Logo;
