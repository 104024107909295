import useMyMenuItems from 'hooks/useMyMenuItems';
import Loader from 'ui-component/Loader';
import { moduleMapper } from './utils';
import { IMenuItemWithFullPath } from 'layout/MainLayout/Sidebar/MenuList/types';
import { getMenuItemSelected } from 'views/RenderRecordType/utils';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import RenderRecordType from 'views/RenderRecordType';
import { useDispatch } from 'store';
import { activeItem } from 'store/slices/menu';

const RenderModule = () => {
    const [menuItem, setMenuItem] = useState<IMenuItemWithFullPath>();
    const { loading, menuItemList } = useMyMenuItems();
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    // console.log('menuItemList', menuItemList);

    useEffect(() => {
        if (!menuItemList || loading) return;
        const menuItemSelected = getMenuItemSelected(menuItemList, location.pathname);
        if (menuItemSelected) {
            setMenuItem(menuItemSelected);
            dispatch(activeItem([menuItemSelected.id]));
        } else {
            navigate('../dashboard');
        }
    }, [location.pathname, menuItemList, navigate, loading, dispatch]);

    if (loading) return <Loader />;

    if (menuItem?.hasRecordTypes) return <RenderRecordType menuItem={menuItem} key={menuItem.recordType.id} />;

    return moduleMapper(menuItem?.route || '');
};

export default RenderModule;
