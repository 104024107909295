import { DataGridPro, GridColDef, gridClasses } from '@mui/x-data-grid-pro';
import { CustomToolbar } from 'ui-component/grids/GridWithLevels/components';
import { alpha, styled } from '@mui/material';

const ODD_OPACITY = 0.2;

export type ListProps = {
    loading: boolean;
    columns?: GridColDef[];
    rows?: readonly Record<string, any>[];
};

export const List = ({ loading, rows = [], columns = [] }: ListProps) => (
    <StripedDataGrid
        loading={loading}
        initialState={{ pinnedColumns: { right: ['actions'] } }}
        columns={columns}
        rows={rows}
        editMode="row"
        components={{
            Toolbar: CustomToolbar
        }}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        disableRowSelectionOnClick
        pagination
        checkboxSelection
    />
);

export const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: theme.palette.grey[200],
        '&:hover, &.Mui-hovered': {
            backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
            '@media (hover: none)': {
                backgroundColor: 'transparent'
            }
        },
        '&.Mui-selected': {
            backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
            '&:hover, &.Mui-hovered': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
                ),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity)
                }
            }
        }
    }
}));
