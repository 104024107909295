import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Autocomplete, Box, Chip, Collapse, FormControl, LinearProgress, TextField } from '@mui/material';
import { useLazyQuery } from '@apollo/client';
import { FindListValues, FindListValuesVariables, ListValue } from 'views/backoffice/CustomLists/types';
import { Clear } from '@mui/icons-material';
import { v4 as UIDV4 } from 'uuid';
import { QUERY_GET_LIST_VALUES } from 'graphql/queries/customLists';

export interface MultiselectEditCellProps {
    onChange: (value: string) => void;
    listId: number;
    initialValue?: string;
    listValues?: ListValue[];
}

export const MultiselectEditCell = ({ onChange, listId, initialValue, listValues }: MultiselectEditCellProps) => {
    const [selectedOption, setSelectedOption] = useState<ListValue[]>([]);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const buttonId = UIDV4();
    const [open, setOpen] = useState(false);
    const [getListValues, { data: listValuesData, loading }] = useLazyQuery<FindListValues, FindListValuesVariables>(QUERY_GET_LIST_VALUES);

    const optionList = useMemo(() => {
        if (listValues)
            return (
                listValues
                    // If initialValue is not enabled but should be shown
                    .filter((el) => {
                        const isEnabled = el.enabled;
                        const valueIsInInitialValue =
                            (typeof initialValue === 'string' && +el.id === +initialValue) ||
                            (Array.isArray(initialValue) && initialValue.map((val) => +val).includes(+el.id));

                        return isEnabled || valueIsInInitialValue;
                    })
                    .sort((a, b) => a.order - b.order) || []
            );
        if (listValuesData)
            return (
                listValuesData.findListValues
                    // If initialValue is not enabled but should be shown
                    .filter((el) => {
                        const isEnabled = el.enabled;
                        const valueIsInInitialValue =
                            (typeof initialValue === 'string' && +el.id === +initialValue) ||
                            (Array.isArray(initialValue) && initialValue.map((val) => +val).includes(+el.id));

                        return isEnabled || valueIsInInitialValue;
                    })
                    .sort((a, b) => a.order - b.order) || []
            );

        return [] as ListValue[];
    }, [initialValue, listValues, listValuesData]);

    const handleOpen = (event: React.SyntheticEvent<Element, Event>) => {
        setOpen(true);
    };

    // TODO: fix this memory leak: Can't perform a React state update on an unmounted component.
    const handleGetListValues = useCallback(
        async (initialList?: ListValue[]) => {
            let list = initialList || [];
            try {
                if (listId && !initialList) {
                    const { data } = await getListValues({ variables: { data: { listId } } });
                    if (data?.findListValues) list = data.findListValues;
                }
                if (list.length) {
                    const values = list.filter((el) => initialValue?.includes(el.id));
                    setSelectedOption(values || []);
                }
            } catch (error) {
                console.log('error', error);
            }
        },
        [getListValues, initialValue, listId]
    );

    useEffect(() => {
        handleGetListValues(listValues);
    }, [handleGetListValues, listValues]);

    useEffect(() => {
        setAnchorEl(document.getElementById(buttonId));
    }, [buttonId]);

    return (
        <Box sx={{ minWidth: 250 }}>
            <FormControl fullWidth disabled={loading}>
                <Autocomplete
                    multiple
                    limitTags={1}
                    id={`multiselect-edit-cell-${UIDV4()}`}
                    openOnFocus
                    renderTags={(value, getTagProps, ownerState) =>
                        value.map((option: any, index: number) => (
                            <Chip
                                sx={(theme) => ({
                                    backgroundColor: `#203461 !important`,
                                    color: `#fff !important`,
                                    borderRadius: '20px !important',
                                    width: '140px !important'
                                })}
                                label={option.userValue?.name || option.value || ''}
                                deleteIcon={<Clear />}
                                {...getTagProps({ index })}
                            />
                        ))
                    }
                    value={selectedOption}
                    onChange={(_e, value) => {
                        onChange(value.length === 0 ? '0' : JSON.stringify(value.map((val: any) => +val.id)));
                        setSelectedOption(value);
                    }}
                    onOpen={handleOpen}
                    onClose={() => setOpen(false)}
                    options={optionList}
                    slotProps={{
                        popper: {
                            anchorEl,
                            open: open && Boolean(anchorEl),
                            placement: 'bottom'
                        }
                    }}
                    componentsProps={{
                        popupIndicator: {
                            id: buttonId
                        }
                    }}
                    getOptionLabel={(option: any) => option && (option.userValue?.name || option.value || '')}
                    filterSelectedOptions
                    renderInput={(params) => <TextField {...params} />}
                />
                <Collapse in={loading}>
                    <LinearProgress />
                </Collapse>
            </FormControl>
        </Box>
    );
};
