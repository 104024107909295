import { useQuery } from '@apollo/client';
import { CancelOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import { QUERY_GET_USERS } from 'graphql/queries/users';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import { SelectAndDragList } from 'ui-component/formComponents';
import { IGetUsers, IUser } from 'views/backoffice/users/types';
import { ListValue } from '../types';

export type UserListDialogProps = {
    open: boolean;
    listName: string;
    onClose: () => void;
    onSubmit: (users: number[]) => Promise<void>;
    defaultValue?: ListValue[];
};

export const UserListDialog = ({ open, listName, onClose, onSubmit, defaultValue }: UserListDialogProps) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);

    const { loading, data } = useQuery<IGetUsers>(QUERY_GET_USERS, {
        onError(error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: `Error getting the users: ${error.message || 'Internal server error.'}`,
                    variant: 'alert',
                    alert: { color: 'error' },
                    close: true
                })
            );
        }
    });

    const defaultValues = useMemo(
        () => defaultValue?.filter((el) => el.userValue && el.enabled).map((el) => Number(el.userValue.id)) || [],
        [defaultValue]
    );

    const userList = useMemo(() => data?.getSaasUsers || [], [data]);

    const handleSubmit = async () => {
        setIsLoading(true);
        await onSubmit(selectedUsers.map((user) => Number(user.id)));
        setIsLoading(false);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" aria-labelledby="form-dialog-title" fullWidth>
            <DialogTitle id="form-dialog-title" sx={{ px: 2, pt: 1 }}>
                <Grid container justifyContent="space-between">
                    <Grid container item xs={10}>
                        <Grid item xs={12}>
                            Update {listName}&apos;s values
                        </Grid>
                    </Grid>
                    <Grid item xs="auto">
                        <IconButton onClick={onClose} color="secondary">
                            <CancelOutlined />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent sx={{ pt: '20px !important', pb: 3 }}>
                <SelectAndDragList
                    list={userList}
                    disabledFields={[]}
                    loadingList={loading}
                    onChangeSelectedFields={(users) => setSelectedUsers(users as IUser[])}
                    defaultSelectedFields={defaultValues}
                />
            </DialogContent>

            <DialogActions sx={{ justifyContent: 'flex-end' }}>
                <Button variant="outlined" color="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <LoadingButton variant="contained" loading={isLoading} color="secondary" onClick={handleSubmit}>
                    Save
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
