// project import
import dashboard from './dashboard';
// import backoffice from './backoffice';
// import accounting from './accounting';

// types
import { NavItemType } from 'types';
// import procurement from './procurement';
import { isPoetProject } from 'utils';
import poetMenuItems from './poet';

const orderBahnMenuItems = [dashboard];

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
    items: isPoetProject() ? poetMenuItems : orderBahnMenuItems
};

export default menuItems;
