import * as React from 'react';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Card, CardContent, Fade } from '@mui/material';

export type UpdateRowsBannerProps = {
    percentage: number;
    value: number;
    total: number;
};

export const UpdateRowsBanner = ({ percentage, total, value }: UpdateRowsBannerProps) => (
    <Card
        component={Fade}
        in={percentage >= 0 && percentage < 100}
        sx={{ width: '80%', maxWidth: '300px', position: 'fixed', bottom: 10, right: 10 }}
        raised
    >
        <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Updating rows
            </Typography>
            {total && <LinearProgressWithLabel variant="determinate" percentage={percentage} value={value} total={total} />}
        </CardContent>
    </Card>
);

const LinearProgressWithLabel = ({
    value,
    total,
    percentage
}: LinearProgressProps & { percentage: number; value: number; total: number }) => (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
            <LinearProgress variant="determinate" value={percentage} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2" color="text.secondary">
                {value}/{total}
            </Typography>
        </Box>
    </Box>
);
