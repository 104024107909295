import { Box, Skeleton } from '@mui/material';
import React from 'react';

const SkeletonList = () => (
    <>
        {[1, 2, 3, 4, 5].map((number) => (
            <Box key={number} sx={{ my: 4 }}>
                <Skeleton variant="rectangular" width={100} height={17} />
                <Skeleton variant="rectangular" sx={{ mr: 2, mt: 2 }} width={175} height={15} />
                <Skeleton variant="rectangular" sx={{ mr: 2, mt: 2 }} width={175} height={15} />
            </Box>
        ))}
    </>
);

export default SkeletonList;
