import { ForwardRefExoticComponent, RefAttributes, forwardRef, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useTheme } from '@mui/styles';
import useConfig from 'hooks/useConfig';
import { useDispatch } from 'store';
import { activeItem, activeMenuItem, openDrawer } from 'store/slices/menu';
import { LinkTarget } from 'types';
import { IAppSection, IMenuItem } from '../types';
import { renderIcon } from '../utils';
import { checkAbsoluteRowIfExist } from '../../utils';

const SaasMenuItem = ({ saasItem, pl, parentRoute }: { saasItem: IMenuItem | IAppSection; pl?: number; parentRoute: string }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { borderRadius } = useConfig();
    const { pathname } = useLocation();
    const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));
    const [selected, setSelected] = useState<string | boolean | null | undefined>(null);

    const itemHandler = (id: string) => {
        dispatch(activeItem([id]));
        dispatch(activeMenuItem(saasItem));
        matchesSM && dispatch(openDrawer(false));
    };

    const listItemProps: {
        component: ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>> | string;
        href?: string;
        target?: LinkTarget;
    } = {
        component: forwardRef((props, ref) => (
            <Link
                ref={ref}
                {...props}
                to={checkAbsoluteRowIfExist(saasItem.route) ? saasItem.route : `${parentRoute}/${saasItem.route}`}
                target="_self"
            />
        ))
    };

    const menuIcon = saasItem.icon ? (
        renderIcon(saasItem.icon)
    ) : (
        <FiberManualRecordIcon
            sx={{
                width: selected === saasItem.id ? 8 : 6,
                height: selected === saasItem.id ? 8 : 6
            }}
            fontSize="medium"
        />
    );

    useEffect(() => {
        const pathNameArr = pathname.split('/');
        const menuItemUrl = pathNameArr[pathNameArr.length - 1];
        setSelected(menuItemUrl === saasItem.route);
    }, [pathname, saasItem.route]);

    return (
        <ListItemButton
            {...listItemProps}
            sx={{
                borderRadius: `${borderRadius}px`,
                mb: 0.5,
                alignItems: 'flex-start',
                // backgroundColor: 'inherit',
                // py: level > 1 ? 1 : 1.25,
                pl: pl || 6
            }}
            selected={!!selected}
            onClick={() => itemHandler(saasItem.id!)}
        >
            <ListItemIcon sx={{ my: 'auto', minWidth: saasItem.icon ? 36 : 18 }}>{menuIcon}</ListItemIcon>
            <ListItemText
                primary={
                    <Typography
                        variant={selected ? 'h5' : 'body1'}
                        color="inherit"
                        sx={{ maxWidth: '75%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                        {saasItem.name}
                    </Typography>
                }
            />
        </ListItemButton>
    );
};

export default SaasMenuItem;
