import { createSlice } from '@reduxjs/toolkit';

// types
import { SectionSliceProps } from 'types/section';

const initialState: SectionSliceProps = {
    sectionName: ''
};

// ==============================|| SLICE - SECTION ||============================== //

const section = createSlice({
    name: 'section',
    initialState,
    reducers: {
        updateSectionName(state, action) {
            const { sectionName } = action.payload;

            state.sectionName = sectionName;
        }
    }
});

export default section.reducer;

export const { updateSectionName } = section.actions;
