import { ConfirmationModalContext, IDialogData } from 'components/providers/ConfirmationModalProvider';
import { useContext } from 'react';

export type ModalContextType = {
    showConfirmation: (newData: IDialogData) => Promise<boolean>;
    hideConfirmation: () => void;
    isShown: boolean;
    toogleLoading: (loading: boolean) => void;
};

export const useConfirmationModalContext = (): ModalContextType => useContext(ConfirmationModalContext);
