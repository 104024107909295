import { format } from 'date-fns';

export const getDateByHour = (value: string): Date | null => {
    if (!value || !value.length) return null;
    const [hours, minutes] = value.split(':');
    const date = new Date();
    date.setHours(Number(hours), Number(minutes), 0, 0);
    return date;
};

export const getTimeValidator = (value: string | undefined): string => {
    if (!value) return '';
    if (Date.parse(value)) return format(new Date(value), 'hh:mm a');
    if (getDateByHour(value as string) !== null)
        if (Date.parse(getDateByHour(value as string)!.toDateString())) return format(getDateByHour(value as string)!, 'hh:mm a');

    return '';
};
