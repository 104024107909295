import { memo, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, IconButton, Tooltip, useMediaQuery } from '@mui/material';

// project imports
import { openDrawer } from 'store/slices/menu';
import { useDispatch, useSelector } from 'store';
import { drawerWidth } from 'store/constant';
import SidebarLogo from './SidebarLogo';
import SidebarDrawer from './SidebarDrawer';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { useConfirmationModalContext } from 'hooks/useConfirmationModal';

// ==============================|| SIDEBAR DRAWER ||============================== //

interface SidebarProps {
    window?: Window;
}

const Sidebar = ({ window }: SidebarProps) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const [show, setShow] = useState(false);
    const { isShown: confirmationModalIsShown } = useConfirmationModalContext();

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const isOpenDialog = useSelector((state) => state.createOrder.openDialog);

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box
            component="nav"
            sx={{
                backgroundColor: theme.palette.secondary.light,
                flexShrink: { md: 0 },
                width: matchUpMd ? drawerWidth : 'auto'
            }}
            aria-label="mailbox folders"
        >
            {!isOpenDialog && !confirmationModalIsShown && (
                <Tooltip
                    open={show}
                    title={`${drawerOpen ? 'Hide Menu' : 'Open Menu'}`}
                    onMouseEnter={() => setShow(true)}
                    onMouseLeave={() => setShow(false)}
                >
                    <IconButton
                        color="secondary"
                        sx={{
                            position: 'absolute',
                            top: '50vh',
                            left: drawerOpen ? `240px` : 'auto',
                            backgroundColor: '#FFF !important',
                            color: theme.palette.secondary.main,
                            zIndex: 1300,
                            '& :hover': {
                                backgroundColor: '#FFF !important'
                            }
                        }}
                        onClick={() => {
                            dispatch(openDrawer(!drawerOpen));
                            setShow(false);
                        }}
                    >
                        {drawerOpen ? <ArrowBackIos /> : <ArrowForwardIos />}
                    </IconButton>
                </Tooltip>
            )}
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={() => dispatch(openDrawer(!drawerOpen))}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        background: drawerOpen ? theme.palette.secondary.light : '#FFF',
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        [theme.breakpoints.up('md')]: {
                            top: '88px'
                        }
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawerOpen && (
                    <>
                        <SidebarLogo />
                        <SidebarDrawer />
                    </>
                )}
            </Drawer>
        </Box>
    );
};

export default memo(Sidebar);
