import { useEffect, useState } from 'react';
import { useTheme } from '@mui/styles';
import useConfig from 'hooks/useConfig';
import { useLocation } from 'react-router-dom';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { IconChevronDown, IconChevronUp } from '@tabler/icons';
import SaasMenuItem from '../SaasMenuItem';
import { IAppSection } from '../types';
import { renderIcon } from '../utils';

const AppSections = ({ section, parentRoute }: { section: IAppSection; parentRoute: string }) => {
    const theme = useTheme();
    const { borderRadius } = useConfig();
    const { pathname } = useLocation();

    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<string | boolean | null | undefined>(null);

    const handleClick = () => {
        setOpen(!open);
        setSelected(!open ? section.id : null);
    };

    useEffect(() => {
        if (section.menuItems.length > 1) setSelected(pathname.includes(section.route));
    }, [pathname, section]);

    const menuIcon = section.icon ? (
        renderIcon(section.icon)
    ) : (
        <FiberManualRecordIcon
            sx={{
                width: selected === section.id ? 8 : 6,
                height: selected === section.id ? 8 : 6
            }}
            fontSize="medium"
        />
    );

    if (section.menuItems.length === 1)
        return <SaasMenuItem saasItem={section.menuItems[0]} pl={4} parentRoute={`${parentRoute}/${section.route}`} />;

    return section.menuItems.length ? (
        <>
            <ListItemButton
                sx={{
                    borderRadius: `${borderRadius}px`,
                    mb: 0.5,
                    alignItems: 'flex-start',
                    // backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                    py: 1.25,
                    pl: 4
                }}
                selected={selected === section.id}
                onClick={handleClick}
            >
                <ListItemIcon sx={{ my: 'auto', minWidth: section.icon ? 36 : 18 }}>{menuIcon}</ListItemIcon>
                <ListItemText
                    primary={
                        <Typography variant={selected === section.id ? 'h5' : 'body1'} color="inherit" sx={{ my: 'auto' }}>
                            {section.name}
                        </Typography>
                    }
                />
                {open ? (
                    <IconChevronUp stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                ) : (
                    <IconChevronDown stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                )}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {open && (
                    <List
                        component="div"
                        disablePadding
                        sx={{
                            position: 'relative',
                            '&:after': {
                                content: "''",
                                position: 'absolute',
                                left: '32px',
                                top: 0,
                                height: '100%',
                                width: '1px',
                                opacity: theme.palette.mode === 'dark' ? 0.2 : 1,
                                background: theme.palette.mode === 'dark' ? theme.palette.dark.light : theme.palette.primary.light
                            }
                        }}
                    >
                        {section.menuItems.map((item) => (
                            <SaasMenuItem saasItem={item} key={item.id} parentRoute={`${parentRoute}/${section.route}`} />
                        ))}
                    </List>
                )}
            </Collapse>
        </>
    ) : null;
};

export default AppSections;
