import React from 'react';
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

import ColorValue from 'assets/scss/_themes-vars.module.scss';

/**
 * @deprecated Now it should be used the ConfirmationModalContext
 */
const SaveDialog = ({ open, onClose, onSubmit }: { open: boolean; onClose: () => void; onSubmit: () => void }) => (
    <div>
        <Dialog open={open} onClose={onClose}>
            {open && (
                <>
                    <CancelRoundedIcon style={{ color: ColorValue.secondaryMain, marginLeft: '336px', opacity: '60%' }} onClick={onClose} />
                    <DialogTitle id="alert-dialog-title">Keep changes?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">Are you sure you want to keep these changes?</DialogContentText>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        defaultChecked
                                        sx={{ color: ColorValue.secondaryMain, '&.Mui-checked': { color: ColorValue.secondaryMain } }}
                                    />
                                }
                                label="Don't show this again"
                            />
                        </FormGroup>
                    </DialogContent>

                    <DialogActions>
                        <Button
                            variant="outlined"
                            style={{ color: ColorValue.secondaryMain, borderColor: ColorValue.secondaryMain }}
                            onClick={onClose}
                            type="button"
                        >
                            No, cancel
                        </Button>
                        <Button variant="contained" type="submit" style={{ backgroundColor: ColorValue.secondaryMain }} onClick={onSubmit}>
                            Yes, Confirm
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    </div>
);

export default SaveDialog;
