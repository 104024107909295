import React from 'react';
import { BreadcrumbsItem } from '../types';
import { Breadcrumbs, Link, Typography } from '@mui/material';

export type BreadcrumbsProps = {
    items: BreadcrumbsItem[];
};

export const GridLevelBreadcrumbs = ({ items }: BreadcrumbsProps) => (
    <Breadcrumbs separator={<Typography variant="h3">&gt;</Typography>} aria-label="breadcrumb">
        {items.map(({ label, onClick }, index) =>
            index === items.length - 1 ? (
                <Typography variant="h3" color="secondary" fontFamily="Poppins, sans-serif">
                    {label}
                </Typography>
            ) : (
                <Link
                    key={index}
                    component={Typography}
                    variant="h3"
                    underline="hover"
                    color="inherit"
                    onClick={onClick}
                    sx={{ cursor: 'pointer' }}
                    className="MuiCardHeader-title"
                >
                    {label}
                </Link>
            )
        )}
    </Breadcrumbs>
);
