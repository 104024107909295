import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';
import { RouteObject } from 'react-router-dom';

// login routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/Register3')));
const AuthForgotPassword = Loadable(lazy(() => import('views/pages/authentication/ForgotPassword3')));
const RecoverPassword = Loadable(lazy(() => import('views/pages/authentication/RecoverPassword')));
const PasswordReset = Loadable(lazy(() => import('views/pages/authentication/PasswordReset')));
const ChangePassword = Loadable(lazy(() => import('views/pages/authentication/ChangePassword')));
const PasswordConfigured = Loadable(lazy(() => import('views/pages/authentication/PasswordConfigured')));
const UserConfirmation = Loadable(lazy(() => import('views/pages/UserConfirmation')));
const SetUpMFA = Loadable(lazy(() => import('views/pages/authentication/SetUpMFA')));
const SelectTenant = Loadable(lazy(() => import('views/pages/authentication/SelectTenant')));
// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes: RouteObject = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: 'login',
            element: <AuthLogin />,
            index: true
        },
        { path: 'setupmfa', element: <SetUpMFA />, index: true },
        { path: 'select-tenant', element: <SelectTenant />, index: true },
        {
            path: 'register',
            element: <AuthRegister />,
            index: true
        },
        {
            path: 'forgot',
            element: <AuthForgotPassword />,
            index: true
        },
        {
            path: 'recover',
            element: <RecoverPassword />,
            index: true
        },
        {
            path: 'reset',
            element: <PasswordReset />,
            index: true
        },
        {
            path: 'user-confirmation/:userId',
            element: <UserConfirmation />,
            index: true
        },
        {
            path: 'change',
            element: <ChangePassword />,
            index: true
        },
        {
            path: 'configured',
            element: <PasswordConfigured />,
            index: true
        }
    ]
};

export default LoginRoutes;
