import { GridCellModes, GridColDef } from '@mui/x-data-grid-pro';

/**
 * Generate a new view Model based on the current model
 *
 * @param currentModel {Object} current edit model of the grid
 */
export const generateViewModel = (currentModel: Record<string, any>) => {
    const newModel: Record<string, any> = {};

    for (const id in currentModel) {
        if (Object.prototype.hasOwnProperty.call(currentModel, id)) {
            const row = currentModel[id];
            const rowModel: Record<string, any> = {};
            for (const field in row) {
                if (Object.prototype.hasOwnProperty.call(row, field)) {
                    rowModel[field] = { mode: GridCellModes.View, ignoreModifications: true };
                }
            }
            newModel[id] = rowModel;
        }
    }

    return newModel;
};

/**
 * Generate a new edit Model based on the fields
 *
 * @param headerFields {GridColDef[]} current edit model of the grid
 * @param ids {string[]} ids array
 */
export const generateEditModel = (headerFields: GridColDef[], ids: string[]): Record<string, any> => {
    const columns = headerFields
        .filter((el) => el.editable)
        .reduce((acc, el) => ({ ...acc, [el.field]: { mode: GridCellModes.Edit } }), {});
    return ids.reduce<Record<string, any>>((acc, row: string) => ({ ...acc, [row]: columns }), {});
};

/**
 * returns true if there's any cell in edit mode or false
 *
 * @param currentModel {Object} current edit model of the grid
 */
export const checkIsEditing = (currentModel: Record<string, any>) => {
    for (const id in currentModel) {
        if (Object.prototype.hasOwnProperty.call(currentModel, id)) {
            const row = currentModel[id];
            if (Object.keys(row).some((key) => row[key].mode === GridCellModes.Edit)) return true;
        }
    }

    return false;
};
