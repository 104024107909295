import { IRecordHeaders } from '../types';
/**
 * @deprecated now use getAditionalFieldTag
 */
export const getFieldIdByHeader = (headerId: any, record: any) => {
    const result = record.additionalFields.find((el: any) => {
        const [, , fieldId] = el.tag.split(';');
        const secondOptionFieldId = !fieldId && el.tag.split(':')[1].split(';')[1];
        return fieldId ? Number(fieldId) === Number(headerId) : Number(secondOptionFieldId) === Number(headerId);
    });
    return result?.id;
};

export const getAditionalFieldTag = (headerId: string, record: IRecordHeaders) => {
    const aditionalFieldObj = record.additionalFields.find((el: any) => {
        const [, , fieldId] = el.tag.split(';');
        const secondOptionFieldId = !fieldId && el.tag.split(':')[1].split(';')[1];
        if ([Number(fieldId), Number(secondOptionFieldId)].includes(Number(headerId))) return true;
        return false;
    });
    return aditionalFieldObj?.tag || `;;${headerId}`;
};
