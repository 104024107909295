import { useRoutes } from 'react-router-dom';

// routes
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import OtherRoutes from './OtherRoutes';
import RedirectRoutes from './RedirectRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([...OtherRoutes, LoginRoutes, MainRoutes, RedirectRoutes]);
}
