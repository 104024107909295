import { IGridPreference } from '../types';

const updatePreferenceListTypes = {
    CHANGE_DEFAULT: 'CHANGE_DEFAULT',
    UPDATE_GRID: 'UPDATE_GRID',
    DELETE: 'DELETE',
    NEW: 'NEW'
} as const;

export type ChangeListType = keyof typeof updatePreferenceListTypes;

/**
 *  Returns preference list depending of its type
 *
 * @param list {IGridPreference[]}
 * @param preferenceObj {IGridPreference}
 * @param type {ChangeListType}
 * @returns
 */
export const getUpdatedPreferenceList = (list: IGridPreference[], preferenceObj: IGridPreference, type: ChangeListType) => {
    switch (type) {
        case 'CHANGE_DEFAULT':
            return list.map((el) => (Number(el.id) === Number(preferenceObj.id) ? preferenceObj : { ...el, isDefault: false }));

        case 'UPDATE_GRID':
            return list.map((el) => (Number(el.id) === Number(preferenceObj.id) ? preferenceObj : { ...el, isDefault: false }));

        case 'DELETE':
            return list.filter((el) => Number(el.id) !== Number(preferenceObj.id));

        case 'NEW':
            return [...list.map((el) => ({ ...el, isDefault: false })), preferenceObj];

        default:
            return list;
    }
};

/**
 * Return the default grid preference or the one with the id selected
 *
 * @param grids {IGridPreference[]}
 * @param selectedId {Number}
 */
export const getDefaultGrid = (grids: IGridPreference[], selectedId?: number) => {
    let selectedGrid: IGridPreference | undefined;

    if (selectedId) selectedGrid = grids.find((el) => +el.id === selectedId);
    else selectedGrid = grids.find((el) => el.isDefault);

    return selectedGrid || grids[0];
};

/**
 * Checks if page size of given grid is more than 200
 *
 * @param selectedOption {IGridPreference}
 */
export const checkIfMoreThan200 = (grids: IGridPreference) => {
    const IsMoreThan200 = +(grids.gridOptions[0]?.pageSize || 0) > 200;
    return IsMoreThan200;
};
