import { useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import { Clear, ExpandMore, FilterListOff, Layers, LayersClear, Refresh, Save, SwapVert } from '@mui/icons-material';
import { Button, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';
import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
    useGridApiContext
} from '@mui/x-data-grid-pro';
import { useSelector } from 'store';
import { isSelectedDeletedRecords } from 'ui-component/records/utils/deleteHelpers';
import { GridToolbarEdit } from './GridToolbarEdit';
import { GridToolbarActions } from './GridToolbarActions';
import useMyRole from 'hooks/useMyRole';
import { checkIsEditing } from 'ui-component/records/utils/editHelpers';
import { LazyQueryExecFunction, OperationVariables } from '@apollo/client';
import { GetZipFileSignedURL } from 'ui-component/records/types';

const useStyles = makeStyles(({ palette }) => ({
    gridOptionsButton: {
        padding: '8px 16px',
        color: palette.primary[400],
        backgroundColor: palette.primary[300],
        borderRadius: '30px'
    },
    gridToolbarCustomFont: { fontSize: '0.8125rem', fontWeight: '500', color: palette.primary[400], marginLeft: '4px' },
    gridToolbarCustomFontBlack: { fontSize: '0.8125rem', fontWeight: '500', color: palette.primary[400], marginLeft: '4px' }
}));

export interface ICustomToolbar {
    handleGridRefresh: () => Promise<void>;
    showResetRowFilters: boolean;
    handleResetRowFilters: () => void;
    showResetRowOrder: boolean;
    handleResetRowOrder: () => void;
    onClickEdit: () => void;
    rowsEditModel: Record<string, any>;
    onSave: () => void;
    onDelete: () => Promise<void>;
    showOnlyEnabledRecords: boolean;
    onToggleShowRecords: () => void;
    recordType: string;
    onClickBulkEdit: () => void;
    updateLoading: boolean;
    rowsSelectedAmount: number;
    getZipFileUrl: LazyQueryExecFunction<GetZipFileSignedURL, OperationVariables>;
    isSuperRecordType?: boolean;
}

export const CustomToolbar = ({
    handleGridRefresh,
    showResetRowFilters,
    handleResetRowFilters,
    showResetRowOrder,
    handleResetRowOrder,
    onClickEdit,
    rowsEditModel,
    onSave,
    onDelete,
    showOnlyEnabledRecords,
    onToggleShowRecords,
    recordType,
    onClickBulkEdit,
    updateLoading,
    rowsSelectedAmount,
    getZipFileUrl,
    isSuperRecordType = false
}: ICustomToolbar) => {
    const apiRef = useGridApiContext();
    const classes = useStyles();
    const role = useMyRole();
    const { actionData, selectedItem } = useSelector((store) => store.menu);

    const isAllowedDelete = useMemo(
        () =>
            actionData
                .find((el) => Number(el.menuItem.id) === Number(selectedItem) && Number(el.role.id) === (role as number))
                ?.menuItemActions.find((el) => el.name.toLowerCase().includes('soft delete'))?.enabled,
        [actionData, role, selectedItem]
    );

    const rowsSelected = apiRef.current.getSelectedRows();
    const isEditing = checkIsEditing(rowsEditModel);
    const isAlreadyDeleted = isSelectedDeletedRecords(rowsSelected);

    return (
        <GridToolbarContainer sx={{ gap: 0.5 }}>
            <GridToolbarColumnsButton
                sx={({ palette }) => ({
                    color: palette.primary[400],
                    backgroundColor: palette.primary[300],
                    borderRadius: '30px',
                    padding: '8px 16px'
                })}
                endIcon={<ExpandMore />}
            />
            <GridToolbarFilterButton
                sx={({ palette }) => ({
                    color: palette.primary[400],
                    backgroundColor: palette.primary[300],
                    borderRadius: '30px',
                    padding: '8px 16px'
                })}
                componentsProps={{ button: { endIcon: <ExpandMore /> } }}
            />
            {showResetRowFilters && (
                <Tooltip title="Reset Filters">
                    <IconButton
                        color="primary"
                        onClick={(e) => {
                            e.preventDefault();
                            handleResetRowFilters();
                        }}
                        className={classes.gridOptionsButton}
                    >
                        <FilterListOff />
                        <Typography variant="body1" className={classes.gridToolbarCustomFont} component="div">
                            Reset Filters
                        </Typography>
                        <Clear fontSize="small" className={classes.gridToolbarCustomFontBlack} />
                    </IconButton>
                </Tooltip>
            )}
            <GridToolbarDensitySelector
                sx={({ palette }) => ({
                    color: palette.primary[400],
                    backgroundColor: palette.primary[300],
                    borderRadius: '30px',
                    padding: '8px 16px'
                })}
                endIcon={<ExpandMore />}
            />
            <GridToolbarExport
                sx={(theme: { palette: { primary: any[] } }) => ({
                    color: theme.palette.primary[400],
                    backgroundColor: theme.palette.primary[300],
                    borderRadius: '30px',
                    padding: '8px 16px'
                })}
                endIcon={<ExpandMore />}
            />
            <Tooltip title="Refresh">
                <IconButton
                    color="primary"
                    onClick={(e) => {
                        e.preventDefault();
                        handleGridRefresh();
                    }}
                    className={classes.gridOptionsButton}
                >
                    <Refresh fontSize="small" />
                    <Typography variant="body1" className={classes.gridToolbarCustomFont} component="div">
                        Refresh Grid
                    </Typography>
                </IconButton>
            </Tooltip>
            {isAllowedDelete && (
                <Button
                    startIcon={showOnlyEnabledRecords ? <LayersClear /> : <Layers />}
                    size="medium"
                    color="primary"
                    onClick={onToggleShowRecords}
                    sx={({ palette }) => ({
                        fontSize: '13px',
                        color: palette.primary[400],
                        backgroundColor: palette.primary[300],
                        borderRadius: '30px',
                        padding: '8px 16px'
                    })}
                >
                    {showOnlyEnabledRecords ? 'Show' : 'Hide'} delete Records
                </Button>
            )}
            {showResetRowOrder && (
                <Tooltip title="Restore Sort">
                    <IconButton
                        color="primary"
                        onClick={(e) => {
                            e.preventDefault();
                            handleResetRowOrder();
                        }}
                        className={classes.gridOptionsButton}
                    >
                        <SwapVert fontSize="small" />
                        <Typography variant="body1" className={classes.gridToolbarCustomFont} component="div">
                            Sort
                        </Typography>
                        <Clear fontSize="small" className={classes.gridToolbarCustomFontBlack} />
                    </IconButton>
                </Tooltip>
            )}
            {!isSuperRecordType && (
                <>
                    <GridToolbarEdit
                        onClick={onClickEdit}
                        show={!!rowsSelected.size || isEditing}
                        isEditing={isEditing}
                        updateLoading={updateLoading}
                    />
                    <GridToolbarActions
                        getZipFileUrl={getZipFileUrl}
                        trigger={recordType}
                        onDelete={onDelete}
                        recordType={recordType}
                        rowsSelectedAmount={rowsSelectedAmount === 0 ? rowsSelected.size : rowsSelectedAmount}
                        onClickBulkEdit={onClickBulkEdit}
                        showDelete={isAllowedDelete && !isAlreadyDeleted}
                        show={(isAllowedDelete && !isAlreadyDeleted) || !!rowsSelected.size}
                    />
                </>
            )}

            {isEditing && (
                <>
                    <Tooltip title="Save">
                        <IconButton
                            sx={({ palette }) => ({
                                color: palette.primary[400],
                                backgroundColor: palette.primary[300]
                            })}
                            size="large"
                            color="primary"
                            onClick={(e) => {
                                e.stopPropagation();
                                onSave();
                            }}
                        >
                            {updateLoading ? <CircularProgress color="inherit" size={14} /> : <Save />}
                        </IconButton>
                    </Tooltip>
                    {/* <Tooltip title="Save in all rows">
                        <IconButton color="primary" onClick={editBulk}>
                            <SaveAs />
                        </IconButton>
                    </Tooltip> */}
                </>
            )}
            <div style={{ flexGrow: 1 }} />
        </GridToolbarContainer>
    );
};
