import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export enum OrderDialogs {
    MANUFACTURER_ORDER = 'MANUFACTURER_ORDER',
    PURCHASE_ORDER = 'PURCHASE_ORDER',
    RFQ_ORDER = 'RFQ_ORDER',
    QUOTE = 'QUOTE'
}

const initialState: { openDialog: OrderDialogs | null } = {
    openDialog: null
};

export const orderSlice = createSlice({
    name: 'createOrder',
    initialState,
    reducers: {
        openDialog(state, action: PayloadAction<OrderDialogs>) {
            state.openDialog = action.payload;
        },
        closeDialog(state) {
            state.openDialog = null;
        }
    }
});

export const { openDialog, closeDialog } = orderSlice.actions;
export default orderSlice.reducer;
