import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { PropsWithChildren } from 'react';

export const PanelFieldSection = ({
    title,
    children,
    isFocused = false,
    noPadding = false,
    isRecordViewer = false,
    labelStyle = null,
    hideTitle = false
}: PropsWithChildren<{
    title: string | JSX.Element;
    isRecordViewer?: boolean;
    noPadding?: boolean;
    isFocused?: boolean;
    labelStyle?: any;
    hideTitle?: boolean;
}>) => {
    const theme = useTheme();
    if (hideTitle) return <>{children}</>;
    return (
        <Grid item xs={12} sx={{ py: noPadding ? 0 : '10px' }}>
            <Typography
                style={
                    labelStyle ?? {
                        paddingTop: 18,
                        paddingLeft: 25,
                        fontWeight: 600,
                        fontSize: '13px',
                        textOverflow: 'ellipsis',
                        // whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        color: isFocused ? theme.palette.secondary[800] : '#616161'
                    }
                }
            >
                {title}
            </Typography>
            <Grid style={{ paddingTop: isRecordViewer ? 5 : 18, paddingLeft: isRecordViewer ? 25 : 10, paddingRight: 10 }}>{children}</Grid>
        </Grid>
    );
};
