import { IUserDataToken } from 'utils/types';
import jwtDecode from 'jwt-decode';
import { useEffect, useState } from 'react';

const useMyRole = () => {
    const tenantId = localStorage.getItem('tenant_id');
    const token = localStorage.getItem('backend_jwt');
    const [role, setRole] = useState<number | null>(null);

    useEffect(() => {
        if (!tenantId || !token) return;
        const userData: IUserDataToken = jwtDecode(token);
        const myTenantData = userData.tenants.find((tenant) => tenant.tenantId === Number(tenantId));
        if (myTenantData) setRole(Number(myTenantData.role) || null);
    }, [tenantId, token]);

    return role;
};

export default useMyRole;
