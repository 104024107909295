import React, { useState } from 'react';
import { Box, Checkbox, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { GridApiPro } from '@mui/x-data-grid-pro';

export type CustomCheckboxSelectionHeaderProps = {
    totalRecords?: number;
    isAllSelected: boolean;
    setAllRecordsSelected: (value: boolean) => void;
    apiRef: React.MutableRefObject<GridApiPro>;
};

export const CustomCheckboxSelectionHeader = ({
    totalRecords,
    setAllRecordsSelected,
    isAllSelected,
    apiRef
}: CustomCheckboxSelectionHeaderProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Box>
            <IconButton onClick={handleClick} id="checkbox-selection-button">
                <Checkbox checked={isAllSelected || apiRef.current.getAllRowIds().length === apiRef.current.getSelectedRows().size} />
            </IconButton>
            <Menu
                id="checkbox-selection"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'checkbox-selection-button'
                }}
            >
                <MenuItem
                    divider={apiRef.current.getSelectedRows().size > 0}
                    onClick={() => {
                        setAllRecordsSelected(false);
                        apiRef.current.setRowSelectionModel(apiRef.current.getAllRowIds());
                        handleClose();
                    }}
                >
                    <Typography>
                        Select records in page: <span style={{ fontWeight: 'bold' }}>{apiRef.current.getAllRowIds().length}</span>
                    </Typography>
                </MenuItem>
                {/* <MenuItem
                    onClick={() => {
                        setAllRecordsSelected(true);
                        apiRef.current.setRowSelectionModel(apiRef.current.getAllRowIds());
                        handleClose();
                    }}
                >
                    <Typography>
                        Select all records: <span style={{ fontWeight: 'bold' }}>{totalRecords}</span>
                    </Typography>
                </MenuItem> */}
                {apiRef.current.getSelectedRows().size > 0 && (
                    <MenuItem
                        onClick={() => {
                            setAllRecordsSelected(false);
                            apiRef.current.setRowSelectionModel([]);
                            handleClose();
                        }}
                    >
                        <Typography>Deselect all</Typography>
                    </MenuItem>
                )}
            </Menu>
        </Box>
    );
};
