import { RouteObject } from 'react-router-dom';
import NotFound from 'views/pages/NotFound';

const OtherRoutes: RouteObject[] = [
    {
        path: '/404',
        element: <NotFound />
    }
];

export default OtherRoutes;
