import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface SubmissionState {
    loading: boolean;
}

const initialState: SubmissionState = {
    loading: false
};

export const submissionSlice = createSlice({
    name: 'submission',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const { setLoading } = submissionSlice.actions;

export default submissionSlice.reducer;
