import React, { useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-pro';

export const CheckboxEditCell = (props: GridRenderEditCellParams) => {
    const { id, value, field } = props;
    const [checked, setChecked] = useState(!!(value && value === 'true'));
    const apiRef = useGridApiContext();

    const handleChange = async (newValue: string) => {
        setChecked(newValue === 'true');
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    return (
        <FormGroup style={{ padding: 10 }}>
            <FormControlLabel
                control={
                    <Checkbox
                        defaultChecked
                        checked={checked}
                        onChange={(e) => {
                            handleChange(String(e.target.checked));
                        }}
                        sx={(theme) => ({
                            color: theme.palette.secondary.main,
                            '&.Mui-checked': { color: theme.palette.primary.dark },
                            '& .MuiSvgIcon-root': { fontSize: 30 }
                        })}
                    />
                }
                label=""
            />
        </FormGroup>
    );
};
