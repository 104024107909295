import { gql } from '@apollo/client';

export const MUTATION_SIGN_UP = gql`
    mutation SaasUserSignUp($data: userAuthentication!) {
        SaasUserSignUp(data: $data) {
            id
            firstName
            lastName
            email
            fireBaseId
        }
    }
`;

export const MUTATION_LOGIN = gql`
    mutation Mutation {
        SaasUserLogin
    }
`;
