/* eslint-disable no-nested-ternary */
// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, IconButton, LinearProgress, Typography } from '@mui/material';
import dayjs from 'dayjs';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
import RelativeTime from 'dayjs/plugin/relativeTime';
import { Circle, Close, Download } from '@mui/icons-material';
import { FileMappedToNotification } from 'ui-component/records/types';

export type FileNotificationProps = {
    download: FileMappedToNotification;
    handleClearDownload: (id: string) => void;
    clearNewTagFromInProgressDownload: (id: string) => void;
};
export const FileNotification = ({ download, handleClearDownload, clearNewTagFromInProgressDownload }: FileNotificationProps) => {
    dayjs.extend(CustomParseFormat);
    dayjs.extend(RelativeTime);
    const theme = useTheme();

    return (
        <Box
            key={download.id}
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start', paddingX: '8px', marginY: '8px' }}
        >
            <IconButton sx={{ backgroundColor: '#FFF', border: '1px solid #D9D9D9' }}>
                {download.status === 'Failed' ? (
                    <Close
                        sx={{
                            color: '#FF0000'
                        }}
                    />
                ) : (
                    <Download
                        sx={{
                            color: theme.palette.secondary.main
                        }}
                    />
                )}
            </IconButton>
            <Box width="75%" sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography
                        sx={{
                            color: '#000',
                            fontWeight: 600,
                            fontSize: '16px',
                            letterSpacing: '0.15px',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        {download.files} Records
                        <Circle
                            sx={{
                                color:
                                    download.status === 'Pending'
                                        ? '#ED6C02'
                                        : download.status === 'Failed'
                                        ? '#FF0000'
                                        : theme.palette.secondary.main,
                                fontSize: '6px',
                                marginX: '4px'
                            }}
                        />
                        <Box
                            component="span"
                            sx={{
                                color:
                                    download.status === 'Pending'
                                        ? '#ED6C02'
                                        : download.status === 'Failed'
                                        ? '#FF0000'
                                        : theme.palette.secondary.main
                            }}
                        >
                            {download.status}
                        </Box>
                    </Typography>
                    <Typography>{download.started.fromNow()}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: '4px' }}>
                    <Box sx={{ width: '90%' }}>
                        {download.status === 'Pending' ? (
                            <LinearProgress variant="determinate" value={download.progress} />
                        ) : (
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    cursor: 'pointer',
                                    color: theme.palette.secondary.main,
                                    textDecoration: 'underline',
                                    textTransform: 'none'
                                }}
                            >
                                {download.fileName}
                            </Typography>
                        )}
                    </Box>
                    {download.status === 'Pending' && <Typography>{download.progress}%</Typography>}
                </Box>
                <Box width="100%" sx={{ display: 'flex', justifyContent: 'flex-end', mt: '8px' }}>
                    <Button
                        sx={{
                            backgroundColor: '#FFF',
                            border: `1px solid ${theme.palette.secondary.main}`,
                            color: theme.palette.secondary.main,
                            borderRadius: '12px'
                        }}
                        size="small"
                        onClick={() => {
                            handleClearDownload(download.id);
                            if (download.status === 'Completed') window.open(download.fileUrl, '_blank');
                        }}
                    >
                        {download.status !== 'Completed' ? 'Cancel' : 'Download'}
                    </Button>
                </Box>
            </Box>
            <Box sx={{ cursor: 'pointer' }} onClick={() => clearNewTagFromInProgressDownload(download.id)}>
                <Circle sx={{ color: download.new ? theme.palette.secondary.main : '#F5F6F7', fontSize: '10px' }} />
            </Box>
        </Box>
    );
};
