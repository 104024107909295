import { ChevronLeft, ChevronRight, FirstPage, LastPage } from '@mui/icons-material';
import { Grid, IconButton, TextField, Typography } from '@mui/material';
import { CurrentPageLabelProps, GoToPageProps, NumberOfPagesProps } from '@react-pdf-viewer/page-navigation';
import React, { ChangeEvent, useState, KeyboardEvent, useEffect } from 'react';

interface IPagination {
    CurrentPageLabel: (props: CurrentPageLabelProps) => React.ReactElement;
    GoToFirstPage: (props: GoToPageProps) => React.ReactElement;
    GoToLastPage: (props: GoToPageProps) => React.ReactElement;
    GoToNextPage: (props: GoToPageProps) => React.ReactElement;
    GoToPreviousPage: (props: GoToPageProps) => React.ReactElement;
    NumberOfPages: (props: NumberOfPagesProps) => React.ReactElement;
    jumpToPage: (pageIndex: number) => void;
}

export const Pagination = ({
    CurrentPageLabel,
    GoToFirstPage,
    GoToLastPage,
    GoToNextPage,
    GoToPreviousPage,
    jumpToPage,
    NumberOfPages
}: IPagination) => (
    <Grid
        container
        alignItems="center"
        justifyContent="space-around"
        id="pdf-pagination-controllers"
        sx={{
            width: '196px',
            padding: '8px',
            backgroundColor: '#FFF',
            borderRadius: '30px',
            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
            position: 'absolute',
            bottom: '8px',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 100
        }}
    >
        <Grid item xs="auto">
            <GoToFirstPage>
                {(props) => (
                    <IconButton
                        sx={{ p: 0 }}
                        onClick={props.onClick}
                        disabled={props.isDisabled}
                        color="secondary"
                        size="small"
                        disableFocusRipple
                    >
                        <FirstPage />
                    </IconButton>
                )}
            </GoToFirstPage>
        </Grid>
        <Grid item xs="auto">
            <GoToPreviousPage>
                {(props) => (
                    <IconButton
                        sx={{ p: 0 }}
                        onClick={props.onClick}
                        disabled={props.isDisabled}
                        color="secondary"
                        size="small"
                        disableFocusRipple
                    >
                        <ChevronLeft />
                    </IconButton>
                )}
            </GoToPreviousPage>
        </Grid>
        <Grid item container xs="auto" alignItems="center">
            <CurrentPageLabel>
                {(props) => (
                    <PaginationTextField currentPage={props.currentPage} pageTotal={props.numberOfPages} onPageChange={jumpToPage} />
                )}
            </CurrentPageLabel>
            <NumberOfPages>{(props) => <Typography variant="caption">of {props.numberOfPages}</Typography>}</NumberOfPages>
        </Grid>
        <Grid item xs="auto">
            <GoToNextPage>
                {(props) => (
                    <IconButton
                        sx={{ p: 0 }}
                        onClick={props.onClick}
                        disabled={props.isDisabled}
                        color="secondary"
                        size="small"
                        disableFocusRipple
                    >
                        <ChevronRight />
                    </IconButton>
                )}
            </GoToNextPage>
        </Grid>
        <Grid item xs="auto">
            <GoToLastPage>
                {(props) => (
                    <IconButton
                        sx={{ p: 0 }}
                        onClick={props.onClick}
                        disabled={props.isDisabled}
                        color="secondary"
                        size="small"
                        disableFocusRipple
                    >
                        <LastPage />
                    </IconButton>
                )}
            </GoToLastPage>
        </Grid>
    </Grid>
);

export type PaginationTextFieldProps = {
    currentPage: number;
    pageTotal: number;
    onPageChange: (pageIndex: number) => void;
};

const PaginationTextField = ({ currentPage, onPageChange, pageTotal }: PaginationTextFieldProps) => {
    const [page, setPage] = useState<string | number>();

    const handleChangePage = (e: KeyboardEvent) => {
        const number = Number(page);
        if (!number || e.key !== 'Enter') return;

        if (number - 1 >= 0) onPageChange(Number(page) - 1);
    };

    const handleEnterChangePage = (e: ChangeEvent<HTMLInputElement>) => {
        if (Number(e.target.value) < 0) return;
        if (Number(e.target.value) <= pageTotal) setPage(e.target.value);
    };

    useEffect(() => {
        setPage(currentPage + 1);
    }, [currentPage]);

    return (
        <TextField
            hiddenLabel
            value={page}
            variant="outlined"
            type="number"
            onChange={handleEnterChangePage}
            size="small"
            onKeyDown={handleChangePage}
            sx={{
                width: 24,
                height: 32,
                bgcolor: 'white',
                mr: '10px',
                '& .MuiOutlinedInput-root': {
                    width: 24,
                    height: 32
                },
                '& input': {
                    textAlign: 'center',
                    px: '0 !important'
                },
                '& input::-webkit-outer-spin-button,& input::-webkit-inner-spin-button': {
                    WebkitAppearance: 'none',
                    margin: 0
                },
                '& input[type=number]': {
                    MozAppearance: 'textfield'
                }
            }}
        />
    );
};

export default Pagination;
