import { forwardRef, useEffect, useImperativeHandle, useState, useMemo, useCallback, MutableRefObject } from 'react';
import { Edit, Save, Close } from '@mui/icons-material';
import {
    GridRowModesModel,
    GridRowModes,
    DataGridPro,
    GridColDef,
    GridRowParams,
    MuiEvent,
    GridActionsCellItem,
    GridEventListener,
    GridRowId,
    GridRowModel,
    DataGridProProps,
    GridSlotsComponent,
    GridRowOrderChangeParams,
    useGridApiRef,
    GridApiPro,
    GRID_CHECKBOX_SELECTION_COL_DEF
} from '@mui/x-data-grid-pro';
// import WarningIcon from '@mui/icons-material/WarningAmber';
import { getOnlyUpdatedRows, orderAlphabetical, randomInteger, reorder } from './utils';
import { GridToolbar, GridToolbarProps } from './components';
import { SxProps } from '@mui/system';
// import { useConfirmationModalContext } from 'hooks/useConfirmationModal';

export type GridWithInlineEditProps = {
    GridComponent?: (props: DataGridProProps) => JSX.Element | null;
    loading: boolean;
    initialRows: readonly Record<string, any>[];
    columns: GridColDef[];
    shouldEdit?: boolean;
    onUpdate: (newRow: Record<string, any>) => Promise<Record<string, any> | undefined>;
    onCreate: (newRow: Record<string, any>) => Promise<Record<string, any> | undefined>;
    disabledReordering?: boolean;
    disabledCheckboxSelection?: boolean;
    fieldToFocus?: string;
    density?: 'standard' | 'comfortable' | 'compact';
    onUpdateOrder?: (newOrder: Record<string, any>[]) => Promise<boolean>;
    gridComponents?: Partial<GridSlotsComponent>;
    autosizeColumns?: boolean;
    pinnedActions?: boolean;
    sx?: SxProps;
    showQuickFilter?: boolean;
    handleShowImportDialog?: () => void;
    onClickEdit?: (row: Record<string, any>) => void;
    isPreview?: boolean;
    onChangeEditModel?: (model: GridRowModesModel) => void;
    hideActionsLabel?: boolean;
    requiredFields?: string[];
    showActionsBeforeCheckbox?: boolean;
    onChangeRowSelection?: (selectedRows: GridRowId[]) => void;
    showBulkDelete?: boolean;
    onBulkDelete?: () => void;
};

export type GridWithInlineEditRef = {
    handleAddClick: (initialValue?: Record<string, any>) => void;
    apiRef: MutableRefObject<GridApiPro>;
};

/**
 * Grid with Inline Edit
 *
 * This component is used to render a grid with inline edit functionality
 * exposing the add new row functionality to the parent component
 *
 */
const GridWithInlineEdit = forwardRef(
    (
        {
            GridComponent = DataGridPro,
            loading,
            initialRows,
            columns,
            onUpdate,
            onCreate,
            disabledReordering,
            disabledCheckboxSelection,
            fieldToFocus,
            onUpdateOrder,
            gridComponents,
            density = 'standard',
            sx,
            onClickEdit,
            shouldEdit = false,
            autosizeColumns = false,
            pinnedActions = false,
            showQuickFilter = false,
            handleShowImportDialog,
            isPreview = false,
            onChangeEditModel,
            hideActionsLabel,
            requiredFields,
            showActionsBeforeCheckbox,
            onChangeRowSelection,
            showBulkDelete,
            onBulkDelete
        }: GridWithInlineEditProps,
        ref
    ) => {
        // const modal = useConfirmationModalContext();
        const apiRef = useGridApiRef();

        const [isReordering, setIsReordering] = useState(false);
        const [rows, setRows] = useState<GridRowModel[]>([...initialRows].sort((a, b) => a.order - b.order));
        const [reorderedRows, setReorderedRows] = useState([...initialRows].sort((a, b) => a.order - b.order));
        const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
        const [checkboxSelection, setCheckboxSelection] = useState<GridRowId[]>([]);

        const handleEditClick = useCallback(
            (id: GridRowId) => () => {
                setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
            },
            [rowModesModel]
        );

        const handleSaveClick = useCallback(
            (id: GridRowId) => () => {
                setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
            },
            [rowModesModel]
        );

        const handleCancelClick = useCallback(
            (id: GridRowId) => () => {
                setRowModesModel({
                    ...rowModesModel,
                    [id]: { mode: GridRowModes.View, ignoreModifications: true }
                });

                const editedRow = rows.find((row) => row.id === id);
                if (editedRow?.isNew) {
                    setRows(rows.filter((row) => row.id !== id));
                }
            },
            [rowModesModel, rows]
        );

        const handleAddClick = (initialValue?: Record<string, any>) => {
            const id = randomInteger();
            const editableFields =
                initialValue || Object.keys(columns.filter((column) => column.editable)).reduce((acc, key) => ({ ...acc, [key]: '' }), {});
            setRows((oldRows) => [...oldRows, { id, isNew: true, ...editableFields }]);
            setRowModesModel((oldModel) => ({
                ...oldModel,
                [id]: { mode: GridRowModes.Edit, fieldToFocus }
            }));
        };

        const processRowUpdate = async (newRow: GridRowModel) => {
            let updatedRow: Record<string, any> | undefined = { ...newRow };
            if (updatedRow.isNew) {
                updatedRow.isNew = false;
                updatedRow = await onCreate(updatedRow);
            } else {
                updatedRow = await onUpdate(updatedRow);
            }

            if (updatedRow) {
                setRows(rows.map((row) => (row.id === newRow.id ? (updatedRow as Record<string, any>) : row)));

                return updatedRow;
            }

            return newRow;
        };

        const processRowReorderingUpdate = async () => {
            const onlyUpdatedRows = getOnlyUpdatedRows(rows, reorderedRows);

            if (onUpdateOrder) {
                const success = await onUpdateOrder(onlyUpdatedRows);
                if (success) {
                    setRows(reorderedRows);
                    setIsReordering(false);
                }
            }
        };

        const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
            event.defaultMuiPrevented = true;
        };

        const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
            event.defaultMuiPrevented = true;
        };

        const handleRowReorderChange = ({ targetIndex, oldIndex }: GridRowOrderChangeParams) => {
            const updatedOrder = reorder<Record<string, any>>(rows, oldIndex, targetIndex);
            setReorderedRows(updatedOrder.map((row, index) => ({ ...row, order: index + 1 })));
        };

        const handleRowAlphabeticalOrder = () => {
            const updatedOrder = orderAlphabetical(rows, fieldToFocus || 'value');
            setReorderedRows(updatedOrder);
            apiRef.current.setRows(updatedOrder);
        };

        const allColumns: GridColDef[] = useMemo(() => {
            const defaultColumns = [...columns];
            if (showActionsBeforeCheckbox) {
                defaultColumns.unshift(GRID_CHECKBOX_SELECTION_COL_DEF);
            }
            if (shouldEdit)
                defaultColumns.unshift({
                    field: 'actions',
                    type: 'actions',
                    renderHeader: () => (hideActionsLabel ? null : 'Actions'),
                    width: 100,
                    cellClassName: 'actions',
                    renderCell: ({ id, row }) => {
                        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                        const actualState = isInEditMode ? apiRef.current.state.editRows[id] : false;

                        if (isInEditMode) {
                            const isDisabled =
                                actualState && requiredFields?.length
                                    ? Object.keys(actualState).some((key) => requiredFields.includes(key) && !actualState[key].value)
                                    : false;
                            return (
                                <>
                                    <GridActionsCellItem icon={<Save />} label="Save" disabled={isDisabled} onClick={handleSaveClick(id)} />
                                    <GridActionsCellItem
                                        icon={<Close />}
                                        label="Cancel"
                                        className="textPrimary"
                                        onClick={handleCancelClick(id)}
                                        color="inherit"
                                    />
                                </>
                            );
                        }

                        return (
                            <>
                                <GridActionsCellItem
                                    icon={<Edit />}
                                    label="Edit"
                                    className="textPrimary"
                                    onClick={!isPreview ? () => (onClickEdit ? onClickEdit(row) : handleEditClick(id)()) : undefined}
                                    color="inherit"
                                />
                            </>
                        );
                    }
                });

            return defaultColumns;
        }, [
            columns,
            showActionsBeforeCheckbox,
            shouldEdit,
            hideActionsLabel,
            rowModesModel,
            apiRef,
            isPreview,
            requiredFields,
            handleSaveClick,
            handleCancelClick,
            onClickEdit,
            handleEditClick
        ]);

        useImperativeHandle(ref, () => ({
            handleAddClick,
            apiRef
        }));

        // Needed to autosize columns after the edit mode change
        useEffect(() => {
            if (autosizeColumns && apiRef.current && rowModesModel)
                apiRef.current.autosizeColumns({
                    includeHeaders: true,
                    includeOutliers: true
                });
        }, [apiRef, autosizeColumns, rowModesModel]);

        useEffect(() => {
            if (initialRows) {
                setRows([...initialRows].sort((a, b) => a.order - b.order).map((row, idx) => ({ ...row, order: idx + 1 })));
                setReorderedRows([...initialRows].sort((a, b) => a.order - b.order).map((row, idx) => ({ ...row, order: idx + 1 })));

                if (autosizeColumns)
                    apiRef.current.autosizeColumns({
                        includeHeaders: true,
                        includeOutliers: true
                    });
            }
        }, [initialRows, apiRef, autosizeColumns]);

        useEffect(() => {
            if (onChangeEditModel) onChangeEditModel(rowModesModel);
        }, [onChangeEditModel, rowModesModel]);

        return (
            <GridComponent
                apiRef={apiRef}
                loading={loading}
                rows={rows}
                columns={allColumns}
                editMode="row"
                density={density}
                rowModesModel={rowModesModel}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                initialState={{ pinnedColumns: { left: pinnedActions ? ['actions'] : [] } }}
                components={{ ...gridComponents, Toolbar: !isPreview ? GridToolbar : undefined }}
                componentsProps={{
                    toolbar: !isPreview
                        ? ({
                              ToolbarButtons: gridComponents?.Toolbar,
                              onAlphabeticalOrder: handleRowAlphabeticalOrder,
                              rowReorder: !disabledReordering,
                              onSaveReorder: processRowReorderingUpdate,
                              toggleReorder: () => {
                                  if (isReordering) apiRef.current.setRows(rows);
                                  setIsReordering(!isReordering);
                                  setReorderedRows(rows);
                              },
                              handleClickDelete: showBulkDelete && checkboxSelection.length && onBulkDelete ? onBulkDelete : undefined,
                              showQuickFilter,
                              handleShowImportDialog,
                              shouldEdit
                          } as GridToolbarProps)
                        : undefined
                }}
                sx={sx}
                onProcessRowUpdateError={(error) => console.log('error', error)}
                getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
                checkboxSelection={!disabledCheckboxSelection}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setCheckboxSelection(newRowSelectionModel);
                    onChangeRowSelection?.(newRowSelectionModel);
                }}
                rowSelectionModel={checkboxSelection}
                disableRowSelectionOnClick
                pagination
                rowReordering={!disabledReordering && isReordering}
                onRowOrderChange={handleRowReorderChange}
            />
        );
    }
);

export default GridWithInlineEdit;
