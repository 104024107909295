import { getFieldName } from './getFieldName';

/* eslint-disable import/prefer-default-export */
export const getAditionalFields = (data: any, headers: any) =>
    data.reduce((acc: any, { tag, value }: { tag: string; value: any }) => {
        const [, , fieldId] = tag.split(';');
        const secondOptionFieldId = !fieldId && tag.split(':')[1].split(';')[1];
        const key = getFieldName(headers, fieldId || secondOptionFieldId);
        return {
            ...acc,
            [key]: value
        };
    }, {});
