import { SvgIcon, SvgIconProps } from '@mui/material';

// IDEA: HOC for icons to pass size
// export type OrderAndEyeProps = {
//     height: number;
//     width: number;
// };

export const OrderAndEye = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_9519_47549)">
                <path
                    d="M17 16.8C18.7227 16.8 20.2591 17.652 21.0091 19C20.2591 20.348 18.7273 21.2 17 21.2C15.2727 21.2 13.7409 20.348 12.9909 19C13.7409 17.652 15.2773 16.8 17 16.8ZM17 16C14.7273 16 12.7864 17.244 12 19C12.7864 20.756 14.7273 22 17 22C19.2727 22 21.2136 20.756 22 19C21.2136 17.244 19.2727 16 17 16ZM17 18C17.6273 18 18.1364 18.448 18.1364 19C18.1364 19.552 17.6273 20 17 20C16.3727 20 15.8636 19.552 15.8636 19C15.8636 18.448 16.3727 18 17 18ZM17 17.2C15.8727 17.2 14.9545 18.008 14.9545 19C14.9545 19.992 15.8727 20.8 17 20.8C18.1273 20.8 19.0455 19.992 19.0455 19C19.0455 18.008 18.1273 17.2 17 17.2Z"
                    fill="currentColor"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6 6C6 5.44772 6.44772 5 7 5H17C17.5523 5 18 5.44772 18 6V14.083C18.7179 14.2034 19.3926 14.4513 20 14.8027V6C20 4.34315 18.6569 3 17 3H7C5.34315 3 4 4.34315 4 6V19C4 20.6569 5.34315 22 7 22H11.3414C11.1203 21.3744 11 20.7013 11 20H7C6.44772 20 6 19.5523 6 19V6Z"
                    fill="currentColor"
                />
                <path opacity="0.5" d="M9 8H15M9 12H15M9 16H11" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
            </g>
            <defs>
                <clipPath id="clip0_9519_47549">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    </SvgIcon>
);
