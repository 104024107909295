import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ITenant } from 'types/tenant';

export interface TenantState {
    tenant: Partial<ITenant> | null;
}

const initialState: TenantState = {
    tenant: null
};

export const tenantSlice = createSlice({
    name: 'userTenant',
    initialState,
    reducers: {
        setTenant: (state, action: PayloadAction<Partial<ITenant>>) => {
            state.tenant = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const { setTenant } = tenantSlice.actions;

export default tenantSlice.reducer;
