import { IconButton, Menu } from '@mui/material';
import { IconZoomPan } from '@tabler/icons';
import React, { MouseEvent, useState } from 'react';

export type ZoomListMenuProps = {
    children?: React.ReactNode;
};

export const ZoomListMenu = ({ children }: ZoomListMenuProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                sx={{ p: 0 }}
                size="small"
                aria-controls={open ? 'zoom-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <IconZoomPan />
            </IconButton>

            <Menu anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{ 'aria-labelledby': 'zoom-button' }}>
                {children}
            </Menu>
        </div>
    );
};
