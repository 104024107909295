import { Box, Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MouseEvent } from 'react';

export interface SaveChangeButtonsProps {
    onClickSaveChanges: (e: MouseEvent<HTMLButtonElement>) => void;
    onClickSaveAsNew: () => void;
}

const useStyles = makeStyles(() => ({
    saveButtonLabel: { width: '100%', overflow: 'hidden', whiteSpace: 'nowrap', display: 'block', textOverflow: 'ellipsis' }
}));

export const SaveChangeButtons = ({ onClickSaveChanges, onClickSaveAsNew }: SaveChangeButtonsProps) => {
    const classes = useStyles();
    return (
        <Grid item xs={3}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box style={{ marginTop: 0, marginRight: '1rem' }}>
                    <Button data-testid="save-changes-btn" onClick={onClickSaveChanges} type="submit" variant="contained" color="secondary">
                        <span className={classes.saveButtonLabel}>Save Changes</span>
                    </Button>
                </Box>
                <Box style={{ marginTop: 0 }}>
                    <Button onClick={onClickSaveAsNew} variant="contained" color="secondary">
                        <span className={classes.saveButtonLabel}>Save as new</span>
                    </Button>
                </Box>
            </Box>
        </Grid>
    );
};
