import React from 'react';
import { Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import Logo from 'ui-component/Logo';
import AuthWrapper1 from './AuthWrapper1';

const NotFound = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <AuthWrapper1>
            <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '100vh' }}>
                <Grid item xs="auto">
                    <MainCard sx={{ width: '100%', maxWidth: { xs: 400, lg: 475 } }}>
                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                            <Grid item sx={{ mb: 3 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 20 }}>
                                    <Logo />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row" alignItems="center" justifyContent="center">
                                    <Grid item>
                                        <Stack alignItems="center" justifyContent="center" spacing={1}>
                                            <Typography color={theme.palette.secondary.main} gutterBottom variant="h1">
                                                404
                                            </Typography>
                                            <Typography variant="caption" fontSize="16px" textAlign={matchDownSM ? 'center' : 'inherit'}>
                                                The page you request is not found.
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </MainCard>
                </Grid>
            </Grid>
        </AuthWrapper1>
    );
};

export default NotFound;
