import React, { useState } from 'react';
import {
    CurrentScaleProps,
    RenderCurrentScaleProps,
    RenderZoomInProps,
    RenderZoomOutProps,
    RenderZoomProps,
    ZoomInProps,
    ZoomOutProps,
    ZoomProps
} from '@react-pdf-viewer/zoom';
import { Grid, IconButton, MenuItem, Typography } from '@mui/material';
import { ZoomInTwoTone, ZoomOutTwoTone } from '@mui/icons-material';
import ZoomOutMapOutlinedIcon from '@mui/icons-material/ZoomOutMapOutlined';
import { SpecialZoomLevel } from '@react-pdf-viewer/core';
import { ZoomListMenu } from './components';

interface IZoom {
    ZoomOut: (props: ZoomOutProps) => JSX.Element;
    ZoomIn: (props: ZoomInProps) => JSX.Element;
    CurrentScale: (props: CurrentScaleProps) => JSX.Element;
    ZoomComponent: (props: ZoomProps) => React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}

export const Zoom = ({ ZoomOut, ZoomIn, CurrentScale, ZoomComponent }: IZoom) => {
    const [isLoadingZoom, setIsLoadingZoom] = useState(false);

    const handleZoom = (fn: () => void) => {
        setIsLoadingZoom(true);
        fn();
        setTimeout(() => {
            setIsLoadingZoom(false);
        }, 1700);
    };
    const ZoomOutButton = () => (
        <ZoomOut>
            {(props: RenderZoomOutProps) => (
                <IconButton sx={{ p: 0 }} size="small" disabled={isLoadingZoom} onClick={() => handleZoom(props.onClick)}>
                    <ZoomOutTwoTone />
                </IconButton>
            )}
        </ZoomOut>
    );

    const ZoomInButton = () => (
        <ZoomIn>
            {(props: RenderZoomInProps) => (
                <IconButton sx={{ p: 0 }} size="small" disabled={isLoadingZoom} onClick={() => handleZoom(props.onClick)}>
                    <ZoomInTwoTone />
                </IconButton>
            )}
        </ZoomIn>
    );
    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            id="pdf-zoom-controllers"
            sx={{
                gap: '10px',
                width: '210px',
                height: '48px',
                padding: '8px',
                backgroundColor: '#FFF',
                borderRadius: '30px',
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                position: 'absolute',
                bottom: '8px',
                right: '32px',
                zIndex: 999,
                pr: 2
            }}
        >
            <Grid item>
                <ZoomOutButton />
            </Grid>
            <Grid item>
                <CurrentScale>
                    {(props: RenderCurrentScaleProps) => <Typography variant="body1">{(props.scale * 100).toFixed(0)}%</Typography>}
                </CurrentScale>
            </Grid>
            <Grid item>
                <ZoomInButton />
            </Grid>
            <Grid>
                <ZoomComponent>
                    {(props: RenderZoomProps) => (
                        <ZoomListMenu>
                            <MenuItem onClick={() => props.onZoom(1.25)}>125%</MenuItem>
                            <MenuItem onClick={() => props.onZoom(1.5)}>150%</MenuItem>
                            <MenuItem onClick={() => props.onZoom(1.75)}>175%</MenuItem>
                        </ZoomListMenu>
                    )}
                </ZoomComponent>
            </Grid>
            <Grid>
                <ZoomComponent>
                    {(props: RenderZoomProps) => (
                        <IconButton sx={{ p: 0 }} size="small" onClick={() => props.onZoom(SpecialZoomLevel.PageWidth)}>
                            <ZoomOutMapOutlinedIcon />
                        </IconButton>
                    )}
                </ZoomComponent>
            </Grid>
        </Grid>
    );
};

export default Zoom;
