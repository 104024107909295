import { Skeleton } from '@mui/material';
import React from 'react';

const SkeletonGrid = () => (
    <div style={{ marginTop: 20, maxHeight: '100%', overflow: 'hidden' }}>
        <Skeleton animation="wave" height={45} style={{ margin: '0 10px', marginBottom: 25 }} />
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((el) => (
            <Skeleton key={el} animation="wave" height={35} style={{ margin: '10px 10px' }} />
        ))}
    </div>
);

export default SkeletonGrid;
