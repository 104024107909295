import { Chip } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-pro';

/**
 * Adapter for StatusEditComponent to use it in DataGrid RenderEditCell
 */
export const StatusEditComponent = ({
    value,
    row,
    fieldId = 'enabled',
    chipActiveSx,
    chipInactiveSx,
    forceShow = false
}: GridRenderEditCellParams<Record<string, any>, boolean> & {
    fieldId?: string;
    chipActiveSx?: SxProps<Theme>;
    chipInactiveSx?: SxProps<Theme>;
    forceShow?: boolean;
}) => {
    const apiRef = useGridApiContext();
    if ((!row.id || typeof value === 'undefined') && !forceShow) return null;

    const handleValueChange = async (newVal: boolean) => {
        if (newVal !== value)
            await apiRef.current.setEditCellValue({
                field: fieldId,
                id: row.id,
                value: newVal
            });
    };

    return (
        <StatusEditComponentBase value={!!value} onChange={handleValueChange} chipActiveSx={chipActiveSx} chipInactiveSx={chipInactiveSx} />
    );
};
export type StatusEditComponentBaseProps = {
    value: boolean;
    onChange: (newVal: boolean) => void;
    chipActiveSx?: SxProps<Theme>;
    chipInactiveSx?: SxProps<Theme>;
};

export const StatusEditComponentBase = ({ value, onChange, chipActiveSx, chipInactiveSx }: StatusEditComponentBaseProps) => {
    const handleValueChange = (newVal: boolean) => async () => {
        onChange(newVal);
    };

    if (value)
        return (
            <Chip
                label="Active"
                size="small"
                onClick={handleValueChange(false)}
                sx={
                    chipActiveSx ||
                    ((theme) => ({
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light + 60,
                        color: theme.palette.success.dark
                    }))
                }
            />
        );
    return (
        <Chip
            label="Inactive"
            size="small"
            onClick={handleValueChange(true)}
            sx={
                chipInactiveSx ||
                ((theme) => ({
                    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light + 80,
                    color: theme.palette.orange.dark
                }))
            }
        />
    );
};
