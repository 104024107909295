import { Close } from '@mui/icons-material';
import { IconButton, InputAdornment, MenuItem, Popover, TextField, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { GridSingleSelectOption } from '..';

export type ListProps = {
    open: boolean;
    anchorEl: HTMLElement | null;
    searchInputRef: React.RefObject<HTMLInputElement | null>;
    containerRef: React.RefObject<HTMLDivElement | null>;
    searchText: string;
    options: GridSingleSelectOption[];
    onClose: () => void;
    onChangeSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSelectOption: (selectedId: number | string) => () => void;
    onClearSearch: () => void;
    fitContent?: boolean;
};

export const List = ({
    open,
    anchorEl,
    searchInputRef,
    containerRef,
    searchText,
    options,
    onClose,
    onChangeSearch,
    onSelectOption,
    onClearSearch,
    fitContent
}: ListProps) => (
    <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: fitContent ? 'left' : 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: fitContent ? 'left' : 'right' }}
        PaperProps={{
            sx: {
                borderRadius: '8px',
                ml: '7px',
                mt: '5px',
                width: fitContent ? 'fit-content' : containerRef.current?.clientWidth || '200px',
                maxHeight: '300px',
                overflowY: 'auto'
            }
        }}
        sx={{
            transform: fitContent ? `translateX(-${containerRef.current?.clientWidth}px)` : undefined
        }}
    >
        <TextField
            variant="filled"
            size="small"
            inputRef={searchInputRef}
            InputProps={{
                hiddenLabel: true,
                disableUnderline: true,
                sx: { bgcolor: '#F5F6F7' },
                endAdornment: searchText.length ? (
                    <InputAdornment position="end">
                        <IconButton aria-label="clear search" onClick={onClearSearch} edge="end">
                            <Close />
                        </IconButton>
                    </InputAdornment>
                ) : undefined
            }}
            value={searchText}
            onChange={onChangeSearch}
            autoFocus
            fullWidth
        />
        {options.length ? (
            options.map((el) => (
                <MenuItem
                    sx={{ border: 'solid 1px #D9D9D9', height: '40px', px: '12px', bgcolor: '#F5F6F7' }}
                    key={el.key || el.id}
                    onClick={onSelectOption(el.id)}
                >
                    {el.renderName || (
                        <Typography fontSize="14px" sx={{ overflow: 'hidden', width: '100%', textOverflow: 'ellipsis' }}>
                            {el.name}
                        </Typography>
                    )}
                </MenuItem>
            ))
        ) : (
            <Typography fontSize="14px" sx={{ py: 2, px: 1 }} color="#C6C4C4" align="center">
                <FormattedMessage id="noOptionsAvailable" />
            </Typography>
        )}
    </Popover>
);
