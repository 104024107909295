import React, { ChangeEvent, useEffect, useState } from 'react';
import { Paper, Grid, Typography, Box } from '@mui/material';
import { AvailableFields, SelectedFields } from './components';

export type SelectAndDragListProps = {
    title?: string;
    list: Record<string, any>[];
    loadingList?: boolean;
    onChangeSelectedFields: <T>(fields: T[]) => void;
    disabledFields: Record<string, any>;
    defaultSelectedFields?: number[];
    readOnly?: boolean;
};

export const SelectAndDragList = ({
    title,
    onChangeSelectedFields,
    disabledFields,
    list,
    loadingList,
    defaultSelectedFields = [],
    readOnly
}: SelectAndDragListProps) => {
    const [selectedFieldList, setSelectedFieldList] = useState<Record<string, any>[]>([]);

    const handleAddToList = (field: Record<string, any>) => (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.checked) handleRemoveSelected(field)();
        else if (!selectedFieldList.map((el) => el.id).includes(field.id)) setSelectedFieldList((s) => [...s, field]);
    };
    const handleRemoveSelected = (field: Record<string, any>) => () => {
        setSelectedFieldList((s) => s.filter((el) => el.id !== field.id));
    };

    const handleSelectAll = () => {
        if (list.length === selectedFieldList.length) setSelectedFieldList([]);
        else setSelectedFieldList(list);
    };

    useEffect(() => {
        onChangeSelectedFields(selectedFieldList);
    }, [onChangeSelectedFields, selectedFieldList]);

    useEffect(() => {
        if (defaultSelectedFields?.length && list.length) {
            setSelectedFieldList(
                list
                    .filter(({ id }) => defaultSelectedFields?.includes(Number(id)) || false)
                    .sort((a, b) => {
                        const orderA = defaultSelectedFields.indexOf(Number(a.id));
                        const orderB = defaultSelectedFields.indexOf(Number(b.id));
                        return orderA - orderB;
                    })
            );
        }
    }, [list, defaultSelectedFields]);

    return (
        <>
            {title && <Typography variant="body1">{title}</Typography>}
            <Grid
                container
                elevation={0}
                component={Paper}
                sx={{ position: 'relative', border: 'solid 1px #CFCFCF', py: 1, minHeight: '300px' }}
            >
                <AvailableFields
                    fieldList={list}
                    selectedAll={!!list.length && list.length === selectedFieldList.length}
                    onChangeSelectAll={handleSelectAll}
                    onSelectField={handleAddToList}
                    selectedFieldList={selectedFieldList}
                />
                <SelectedFields
                    fieldList={selectedFieldList}
                    disabledFields={[disabledFields]}
                    onChangeSelectedFields={(newList: Record<string, any>[]) => setSelectedFieldList(newList)}
                />
                {readOnly && (
                    <Box
                        sx={{
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'transparent',
                            zIndex: 100
                        }}
                    />
                )}
                {!loadingList && !list.length && (
                    <Typography
                        align="center"
                        sx={{
                            position: 'absolute',
                            left: '50%',
                            top: '50%',
                            width: '80%',
                            transform: 'translateX(-50%)'
                        }}
                    >
                        There&apos;s no available items
                    </Typography>
                )}
            </Grid>
        </>
    );
};
