export enum ProjectName {
    POET = 'POET',
    ORDERBAHN = 'ORDERBAHN'
}

const PROJECT_NAME = process.env.REACT_APP_PROJECT_NAME || ProjectName.ORDERBAHN;

export const getProjectName = () => PROJECT_NAME;
export const isProjectName = (name: ProjectName) => PROJECT_NAME === name;
export const isPoetProject = () => PROJECT_NAME === ProjectName.POET;
export const isOrderbahnProject = () => PROJECT_NAME === ProjectName.ORDERBAHN;
