import React, { useEffect } from 'react';
import menuItem from 'menu-items';
import NavGroup from '../NavGroup';
import NavItem from '../NavItem';
import useMyMenuItems from 'hooks/useMyMenuItems';
import { useDispatch } from 'store';
import { useLocation } from 'react-router-dom';
import { activeItem, activeMenuItem } from 'store/slices/menu';

const BaseModuleList = () => {
    const { loading } = useMyMenuItems();
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        const { pathname } = location;
        const pathWithouthTenant = pathname.split('/').slice(2).join('/');
        if (pathWithouthTenant === 'dashboard') {
            dispatch(activeItem(['mainDashboard']));
            dispatch(activeMenuItem(['mainDashboard']));
        }
    }, [dispatch, location]);

    if (loading) return null;

    return (
        <>
            {menuItem.items.map((item) => {
                switch (item.type) {
                    case 'group':
                        return <NavGroup key={item.id} item={item} />;
                    case 'item':
                        return <NavItem key={item.id} item={item} level={0} />;
                    default:
                        return null;
                }
            })}
        </>
    );
};

export default BaseModuleList;
