import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { RootDialog } from 'views/pages/authentication/mfa-components/RootDialog';
import { Box, Button, DialogActions, Grid, MenuItem } from '@mui/material';
import { IRecordField, IRecordFieldsList } from 'ui-component/records/types';
import { FormTextField } from 'ui-component/dialogs/FormDialog';
import { Controller, useForm } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import { getDropdownOptionList } from 'ui-component/RecordView/utils';
import { useLazyQuery } from '@apollo/client';
import { FindListValues, FindListValuesVariables } from 'views/backoffice/CustomLists/types';
import { QUERY_FIND_LIST_VALUES } from 'graphql/queries/lists';

import { PanelFieldSection } from 'views/CreateRecord/components';
import { RenderInputBreakLine } from 'ui-component/RecordView/components/RenderInputBreakLine';
import useMyDropdowns from 'hooks/useMyDropdowns';

interface BulkEditDialogProps {
    setOpen: (val: boolean) => void;
    open: boolean;
    recordTypeId: number;
    recordTypeName: string;
    columns: string[];
    formatHeaderName: (name: string) => string;
    fields: IRecordFieldsList | undefined;
    onSaveBulkEdition: (attribute: string, Value: any) => void;
}

interface BulkEditDialogForm {
    attribute: string;
    Value: any;
}

const BulkEditDialog = ({
    setOpen,
    open,
    recordTypeId,
    recordTypeName,
    columns,
    formatHeaderName,
    fields,
    onSaveBulkEdition
}: BulkEditDialogProps) => {
    const theme = useTheme();
    const [isCustomList, setIsCustomList] = React.useState(false);
    const [options, setOptions] = useState<any[]>([]);

    const [findListValue] = useLazyQuery<FindListValues, FindListValuesVariables>(QUERY_FIND_LIST_VALUES, {
        fetchPolicy: 'no-cache'
    });

    const { problemCodeList, recordStatusList, usersList, targetTenantList } = useMyDropdowns({ recordTypeId });

    const {
        control,
        resetField,
        watch,
        formState: { errors },
        getValues,
        setValue,
        setError
    } = useForm<BulkEditDialogForm>({
        mode: 'onChange',
        defaultValues: {
            attribute: 'null',
            Value: undefined
        }
    });

    const attributeSelected = watch('attribute');

    const onChangeAttribute = useCallback(
        async (value: string) => {
            setIsCustomList(Boolean(fields?.[value].listType));
            if (fields?.[value].listType) {
                const { data } = await findListValue({ variables: { data: { listId: Number(fields?.[value].listType?.id) || 0 } } });
                setOptions(data?.findListValues.filter((el) => el.enabled).sort((a, b) => a.order - b.order) || []);
            } else {
                setOptions(getDropdownOptionList(value as any, [], recordStatusList, problemCodeList, usersList, targetTenantList));
            }
        },
        [fields, findListValue, problemCodeList, recordStatusList, usersList, targetTenantList]
    );

    useEffect(() => {
        setValue('Value', '');
        if (attributeSelected !== 'null') onChangeAttribute(String(attributeSelected));
    }, [onChangeAttribute, attributeSelected, setValue, resetField]);

    const handleClose = () => {
        resetField('attribute');
        resetField('Value');
        setOpen(false);
    };

    const handleSave = () => {
        const { attribute, Value } = getValues();
        let sendingValue = Value;
        if (attribute === 'null') {
            setError('attribute', {
                type: 'manual',
                message: 'You have to select an attribute'
            });
            return;
        }
        // special case for checkbox and multiselects
        switch (fields?.[attribute]?.dataType) {
            case 'checkbox':
                sendingValue = !sendingValue ? 'false' : sendingValue;
                break;

            case 'multiselect':
            case 'multiselect-checkbox':
                sendingValue = !sendingValue ? '0' : sendingValue;
                break;

            default:
                break;
        }
        if (fields?.[attribute]?.required && !Value) {
            setError('Value', {
                type: 'manual',
                message: 'You have to enter a value'
            });
            return;
        }
        onSaveBulkEdition(attribute, sendingValue);
        resetField('attribute');
        resetField('Value');
    };

    return (
        <>
            <RootDialog fullWidth onClose={handleClose} aria-labelledby="compatible-mfa-applications-modal" open={open}>
                <DialogTitle
                    sx={{ m: 0, p: 2, paddingTop: '0 !important', paddingBottom: '0 !important' }}
                    id="compatible-mfa-applications-modal"
                >
                    <Box>
                        <Typography gutterBottom variant="h3" sx={{ fontSize: 32, fontWeight: 400 }}>
                            Edit {recordTypeName}
                        </Typography>
                    </Box>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.secondary.main
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSave();
                    }}
                >
                    <DialogContent sx={{ paddingBottom: 0 }}>
                        <PanelFieldSection title="Column">
                            <Controller
                                name="attribute"
                                control={control}
                                rules={{ required: true }}
                                defaultValue="null"
                                render={({ field }) => (
                                    <>
                                        <FormTextField
                                            select
                                            variant="outlined"
                                            error={!!errors.attribute}
                                            helperText={errors.attribute?.message}
                                            focused
                                            fullWidth
                                            sx={{
                                                backgroundColor: '#fafafa !important',
                                                '& fieldset': {
                                                    border: 'none !important'
                                                }
                                            }}
                                            {...field}
                                        >
                                            <MenuItem value="null" disabled>
                                                <Box
                                                    sx={{
                                                        display: 'inline-block',
                                                        verticalAlign: 'middle',
                                                        '& > svg': { height: '20px', marginRight: '5px' }
                                                    }}
                                                />
                                                Please select an option
                                            </MenuItem>
                                            {columns
                                                .filter((el) => !(fields?.[el].dataType === 'object'))
                                                .sort((a, b) => {
                                                    const nameA = formatHeaderName(a).toUpperCase();
                                                    const nameB = formatHeaderName(b).toUpperCase();
                                                    if (nameA < nameB) {
                                                        return -1;
                                                    }
                                                    if (nameA > nameB) {
                                                        return 1;
                                                    }

                                                    // names must be equal
                                                    return 0;
                                                })
                                                .map((option) => (
                                                    <MenuItem key={`key-${option}`} value={option}>
                                                        <Box
                                                            sx={{
                                                                display: 'inline-block',
                                                                verticalAlign: 'middle',
                                                                '& > svg': { height: '20px', marginRight: '5px' }
                                                            }}
                                                        />
                                                        {formatHeaderName(option)}
                                                    </MenuItem>
                                                ))}
                                        </FormTextField>
                                    </>
                                )}
                            />
                        </PanelFieldSection>
                        {attributeSelected !== 'null' && (
                            <RenderInputBreakLine
                                resetField={resetField}
                                setValue={setValue}
                                name="Value"
                                control={control}
                                fieldData={fields?.[attributeSelected] || ({} as IRecordField)}
                                errors={errors}
                                options={options}
                                isCustomList={isCustomList}
                                disabled={false}
                                isRequired={false}
                                fieldName={attributeSelected}
                            />
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Grid container>
                            <Grid item xs={10} sx={{ textAlign: 'right' }}>
                                <Button variant="outlined" color="secondary" onClick={handleClose}>
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item xs={2} sx={{ textAlign: 'center' }}>
                                <Button variant="contained" color="secondary" type="submit" sx={{ marginLeft: '0.3rem', width: '80%' }}>
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </form>
            </RootDialog>
        </>
    );
};

export default BulkEditDialog;
