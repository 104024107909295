import React from 'react';
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

// import ColorValue from 'assets/scss/_themes-vars.module.scss';

const CloseDetailsPanelDialog = ({ open, onClose, onSubmit }: { open: boolean; onClose: () => void; onSubmit: () => void }) => (
    <div>
        <Dialog open={open} onClose={onClose}>
            {open && (
                <>
                    <IconButton onClick={onClose} sx={{ ml: 'auto' }}>
                        <CancelRoundedIcon color="secondary" style={{ opacity: '60%' }} />
                    </IconButton>
                    <DialogTitle id="alert-dialog-title" sx={{ pt: 0 }}>
                        Keep changes?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to close this Line item without saving the changes?
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button variant="outlined" color="secondary" onClick={onClose} type="button">
                            No, cancel
                        </Button>
                        <Button variant="contained" type="submit" color="secondary" onClick={onSubmit}>
                            Yes, Confirm
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    </div>
);

export default CloseDetailsPanelDialog;
