/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/prefer-default-export */
import { GRID_CHECKBOX_SELECTION_COL_DEF, GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GRID_REORDER_COL_DEF } from '@mui/x-data-grid-pro';
import { IGridPreference } from '../types';
import { addOtherFields } from './addOthersFields';

export const getPreferences = (grid: IGridPreference, fields: any) => {
    // let defaultPinnedColumns;
    const defaultPageSize = grid.gridOptions[0].pageSize;
    const defaultPinnedColumns = {
        left: grid.gridOptions[0].pinnedColumns?.left || [],
        right: grid.gridOptions[0].pinnedColumns?.right || []
    };
    const defaultDensityFactor = grid.gridOptions[0].densityFactor;

    const orderedFieldsWithoutPinnedAndGridDef = grid.gridOptions[0].orderedFields.filter(
        (field) => true
        // field !== GRID_DETAIL_PANEL_TOGGLE_COL_DEF.field &&
        // field !== GRID_REORDER_COL_DEF.field &&
        // field !== GRID_CHECKBOX_SELECTION_COL_DEF.field &&
        // !defaultPinnedColumns.left.includes(field) && !defaultPinnedColumns.right.includes(field)
    );

    const defaultOrder = [
        // ...defaultPinnedColumns.left,
        // GRID_DETAIL_PANEL_TOGGLE_COL_DEF.field,
        // GRID_REORDER_COL_DEF.field,
        // GRID_CHECKBOX_SELECTION_COL_DEF.field,
        ...addOtherFields(orderedFieldsWithoutPinnedAndGridDef, Object.keys(fields))
        // ...defaultPinnedColumns.right
    ];
    const defaultFilterModel =
        grid.gridOptions[0].filterModel.map(({ __typename, ...item }: any) => ({
            ...item,
            operatorValue: item.operatorValue[0],
            value: item.value[0]
        })) || [];
    const defaultVisibility = grid.gridOptions[0].hiddenFields.reduce((acc: any, el: string) => ({ ...acc, [el]: false }), fields);
    return { defaultOrder, defaultFilterModel, defaultVisibility, defaultPageSize, defaultPinnedColumns, defaultDensityFactor };
};
