import { gql } from '@apollo/client';

export const QUERY_GET_OBJECT_PROPERTIES = gql`
    query Query($data: FindObjectPropertiesInput!) {
        findObjectProperty(data: $data) {
            id
            name
            listType {
                id
                listName
            }
            order
            isDisplayable
            isRequired
            enabled
            dataType
            objectDefinition {
                id
                name
            }
        }
    }
`;
