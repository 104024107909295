import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { DASHBOARD_PATH } from 'config';
import { GuardProps } from 'types';
import { useEffect } from 'react';

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children, ...props }: GuardProps) => {
    const { isCognitoLoggedIn, isLoggedIn, shouldSkipSetupMFA } = useAuth();
    const navigate = useNavigate();
    const storageshouldSkipSetupMFA = window.localStorage.getItem('shouldSkipSetupMFA');
    const tenantName = localStorage.getItem('tenantPath') as string;
    const tenantId = localStorage.getItem('tenant_id') as string;
    const tenantSelected: boolean = JSON.parse(localStorage.getItem('tenantSelected') || 'false');

    useEffect(() => {
        if (isCognitoLoggedIn) {
            if (!isLoggedIn && !shouldSkipSetupMFA && !storageshouldSkipSetupMFA) {
                navigate('/setupmfa', { replace: true });
            } else if (tenantSelected) {
                // If redirect path is setted send it to that path if not send it to dashboard
                const redirectPath = localStorage.getItem('redirectPath') as string;
                if (redirectPath) {
                    navigate(redirectPath, { replace: true });
                    localStorage.removeItem('redirectPath');
                } else {
                    navigate(DASHBOARD_PATH(tenantName));
                }
            } else if (!tenantId) {
                navigate(`/select-tenant`, { replace: true });
            }
        }
    }, [shouldSkipSetupMFA, isLoggedIn, isCognitoLoggedIn, navigate, storageshouldSkipSetupMFA, tenantSelected, tenantName, tenantId]);

    return children;
};

export default GuestGuard;
