import { gql } from '@apollo/client';

export const QUERY_GET_USER_DETAILS = gql`
    mutation Mutation {
        getUserDetails {
            role {
                description
                name
            }
            tenant {
                description
                id
                name
                url
            }
        }
    }
`;
