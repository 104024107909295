import { Stack, useMediaQuery, useTheme } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { isPoetProject } from 'utils';
import Chip from 'ui-component/extended/Chip';
import MenuList from '../MenuList';

const SidebarDrawer = () => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    return (
        <>
            {/* @ts-ignore */}
            <PerfectScrollbar
                component="div"
                style={{
                    height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    ...(isPoetProject() ? { display: 'flex', flexDirection: 'column' } : {})
                }}
            >
                <MenuList />
                {/* <MenuCard /> */}
                <Stack direction="row" justifyContent="center" sx={{ mb: 2 }}>
                    <Chip
                        label={process.env.REACT_APP_RELEASE_VERSION}
                        disabled
                        chipcolor="secondary"
                        size="small"
                        sx={{ cursor: 'pointer' }}
                    />
                </Stack>
            </PerfectScrollbar>
        </>
    );
};

export default SidebarDrawer;
