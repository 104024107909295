import { gql } from '@apollo/client';

export const QUERY_GET_MY_RECORDS_FILE_BY_ID = gql`
    query Query($recordFileId: Float!) {
        getMyRecordFileById(recordFileId: $recordFileId) {
            id
            fileBucketId
            createdAt
            fileEncoded
            originalFileName
            annotation
        }
    }
`;

export const QUERY_GET_FILTER_AUDIT_LOGS = gql`
    query Query($data: GetAuditLogRecordInputPaginated!) {
        filterAuditLogsForRecord(data: $data) {
            logs {
                action
                datetime
                user {
                    id
                    firstName
                    lastName
                    name
                    email
                }
                changes {
                    newValue
                    oldValue
                    what
                }
                objectsLogs {
                    changes {
                        what
                        oldValue
                        newValue
                    }
                    objectName
                }
            }
            total
        }
    }
`;
