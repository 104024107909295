import { Computer, ExpandMore } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { FormControl, IconButton, MenuItem, Select, SelectChangeEvent, Tooltip } from '@mui/material';
import React from 'react';
import { IGridPreference, ISaasMyGridPreference } from 'ui-component/records/types';

export interface PreferenceSelectProps {
    disabled: boolean;
    value: string;
    onChange: (e: SelectChangeEvent) => void;
    preferenceList: IGridPreference[];
    isAdminSelectedGrid: boolean;
    userPreferencesLoading: boolean;
    userPreferencesData: ISaasMyGridPreference | undefined;
    canConfigureGridPreference: boolean;
    openEditDialog: () => void;
    openDeleteDialog: () => void;
}

export const PreferenceSelect = ({
    disabled,
    onChange,
    preferenceList,
    value,
    isAdminSelectedGrid,
    userPreferencesLoading,
    userPreferencesData,
    canConfigureGridPreference,
    openEditDialog,
    openDeleteDialog
}: PreferenceSelectProps) => (
    <FormControl size="small" disabled={disabled}>
        <Select
            labelId="demo-preference-select-label"
            data-testid="preference-select"
            value={value}
            label="Grid Preferences"
            onChange={onChange}
            notched={false}
            sx={(theme) => ({
                maxHeight: '40px',
                width: '300px',
                background: 'transparent !important',
                '& > .MuiSelect-select': {
                    backgroundColor: 'transparent',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    '& > span': {
                        display: 'block',
                        width: '80%',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                    },
                    '& > span#controlButtons': {
                        display: 'block !important',
                        textAlign: 'right'
                    }
                },
                '& > fieldset': {
                    border: 'none',
                    height: '40px'
                }
            })}
            IconComponent={ExpandMore}
        >
            {[...(preferenceList || [])]
                .sort((a, b) => Number(!!b.role) - Number(!!a.role))
                .map((preference) => (
                    <MenuItem
                        key={preference.id}
                        value={String(preference.id)}
                        sx={{
                            justifyContent: 'space-between',
                            '& > span': {
                                display: 'block',
                                width: '80%',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden'
                            }
                        }}
                    >
                        <span>{preference.savedName}</span>
                        {!isAdminSelectedGrid && !userPreferencesLoading && userPreferencesData && canConfigureGridPreference && (
                            <span style={{ display: 'none' }} id="controlButtons">
                                <Tooltip title="Edit">
                                    <IconButton
                                        size="small"
                                        onMouseDown={(e) => {
                                            e.stopPropagation();
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            openEditDialog();
                                        }}
                                        sx={(theme) => ({
                                            '&:hover': {
                                                backgroundColor: `rgba(0,0,0,0.04) !important`,
                                                color: `${theme.palette.primary[400]} !important`
                                            }
                                        })}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete">
                                    <IconButton
                                        size="small"
                                        onMouseDown={(e) => {
                                            e.stopPropagation();
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            openDeleteDialog();
                                        }}
                                        sx={(theme) => ({
                                            '&:hover': {
                                                backgroundColor: `rgba(0,0,0,0.04) !important`,
                                                color: `${theme.palette.primary[400]} !important`
                                            }
                                        })}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </span>
                        )}
                        {preference.role && <Computer color="secondary" />}
                    </MenuItem>
                ))}
        </Select>
    </FormControl>
);
