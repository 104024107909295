import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

interface ISaveNameDialog {
    open: boolean;
    handleSubmit: (name: string) => void;
    handleClose: () => void;
    defaultValue: string;
    isEdit: boolean;
}

const SaveNameDialog = ({ open, handleSubmit, handleClose, defaultValue, isEdit }: ISaveNameDialog) => {
    const [preferenceName, setPreferenceName] = useState('');

    useEffect(() => {
        if (open) setPreferenceName(defaultValue);
    }, [open, defaultValue]);

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle> {!isEdit ? 'Save New Preference' : 'Edit Preference Name'}</DialogTitle>
            <DialogContent>
                <TextField
                    onChange={(e) => setPreferenceName(e.target.value)}
                    value={preferenceName}
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    fullWidth
                    variant="standard"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>

                <Button onClick={() => handleSubmit(preferenceName)}>Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default SaveNameDialog;
