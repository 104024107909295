import { Box, TextField } from '@mui/material';
// import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect';
import { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';

export interface NumberEditCellProps {
    onChange: (value: string) => void;
    value?: string;
    isCurrency: boolean;
}

export const NumberEditCell = ({ onChange, value, isCurrency }: NumberEditCellProps) => {
    const [numberValue, setNumberValue] = useState(value || null);

    useEffect(() => {
        value && setNumberValue(value);
    }, [value]);

    const handleChange = (newValue: string | null) => {
        if (!newValue) return;
        setNumberValue(newValue);
        onChange(newValue);
    };

    return (
        <Box sx={{ minWidth: 149 }}>
            <NumberFormat
                customInput={TextField}
                value={numberValue}
                fixedDecimalScale={isCurrency}
                onChange={(e: any) => {
                    handleChange(e.target.value);
                }}
                thousandSeparator={isCurrency ? ',' : ''}
                decimalScale={isCurrency ? 2 : 0}
            />
        </Box>
    );
};
