import React from 'react';
import { Navigate } from 'react-router-dom';

const tenantName = localStorage.getItem('tenantPath');

const RedirectRoutes = {
    path: '/',
    element: <Navigate replace to={tenantName || '/404'} />
};

export default RedirectRoutes;
