import { WarningAmber } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

export type HoverAlertProps = {
    type: 'warning';
};

export const HoverAlert = ({ type }: HoverAlertProps) => (
    <Box component={Grid} container sx={{ backgroundColor: '#FF9800', width: '204px', height: '60px', borderRadius: '8px' }}>
        <Grid item container xs={2} justifyContent="center" alignItems="center">
            {type === 'warning' && <WarningAmber htmlColor="white" sx={{ fontSize: '24px' }} />}
        </Grid>
        <Grid item xs={10}>
            <Box sx={{ mt: '5px' }}>
                <Typography variant="h5" fontSize="22px" fontWeight={400} color="white">
                    <FormattedMessage id={type} />
                </Typography>
            </Box>
            <Box sx={{ my: '5px' }}>
                <Typography variant="h5" fontSize="11px" fontWeight={500} color="white">
                    <FormattedMessage id="contactTheListAdmin" />
                </Typography>
            </Box>
        </Grid>
    </Box>
);
