import { gql } from '@apollo/client';
/**
 *  TODO: THIS HAS TO BE DEPRECATED ONCE WE UPLOAD THE LIST MODULE
 */
export const QUERY_FIND_LIST_VALUES = gql`
    query FindListValues($data: FindListsValuesInput!) {
        findListValues(data: $data) {
            createdAt
            enabled
            id
            order
            listId {
                id
            }
            userValue {
                id
                lastName
                firstName
                name
            }
            updatedAt
            value
        }
    }
`;

export const QUERY_GET_LIST_WITH_VALUES = gql`
    query GetLists {
        getLists {
            id
            listValues {
                id
                value
                userValue {
                    name
                }
            }
        }
    }
`;
