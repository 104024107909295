import {
    GRID_ACTIONS_COLUMN_TYPE,
    GRID_CHECKBOX_SELECTION_COL_DEF,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    GRID_REORDER_COL_DEF,
    GridColumnVisibilityModel,
    GridPinnedColumns
} from '@mui/x-data-grid-pro';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { IGridOption } from '../types';

export const generateGridOptionsToSave = (actualState: GridInitialStatePro, densityFactor: number) => {
    const { columns, filter, pagination } = actualState;

    if (!columns) throw Error('columns are not defined');

    const hiddenFields = getHiddenFields(columns.columnVisibilityModel || {});
    const orderedFields = getOrderedFields(columns.orderedFields || []);
    const pinnedColumns = getPinnedColumns(actualState.pinnedColumns || null);
    const filterModel = filter?.filterModel?.items?.map(({ id, field, operator, ...item }) => ({
        value: item.value,
        columnField: field,
        operatorValue: [actualState.filter?.filterModel?.logicOperator?.toLocaleUpperCase(), operator]
    }));
    const pageSize = pagination?.paginationModel?.pageSize;

    return [
        {
            pageSize,
            orderedFields,
            pinnedColumns,
            hiddenFields,
            densityFactor,
            filterModel
        }
    ] as IGridOption[];
};

export const getPinnedColumns = (pinnedColumns: GridPinnedColumns | null): GridPinnedColumns | null => {
    if (!pinnedColumns) return null;
    return {
        left:
            pinnedColumns.left?.filter(
                (field) =>
                    ![GRID_DETAIL_PANEL_TOGGLE_COL_DEF.field, GRID_CHECKBOX_SELECTION_COL_DEF.field, GRID_ACTIONS_COLUMN_TYPE].includes(
                        field
                    )
            ) || [],
        right: pinnedColumns.right || []
    };
};

export const getHiddenFields = (visibilityModel: GridColumnVisibilityModel) =>
    Object.keys(visibilityModel).filter((key) => !visibilityModel[key]);

export const getOrderedFields = (orderedFields: string[]) =>
    orderedFields.filter(
        (field) =>
            ![GRID_DETAIL_PANEL_TOGGLE_COL_DEF.field, GRID_REORDER_COL_DEF.field, GRID_CHECKBOX_SELECTION_COL_DEF.field].includes(field)
    );
