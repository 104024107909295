import { useLazyQuery } from '@apollo/client';
import { QUERY_FIND_RECORD_TYPE, QUERY_FIND_TARGET_TENANTS } from 'graphql/queries/bills';
import { QUERY_GET_PROBLEM_CODE } from 'graphql/queries/problemCode';
import { QUERY_FIND_RECORD_STATUS } from 'graphql/queries/recordStatus';
import { QUERY_GET_USERS } from 'graphql/queries/users';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IFindProblemCodeByRecordType, IFindRecordStatusByRecordType, IProblemCode, IRecordStatus } from 'ui-component/RecordView/types';
import {
    GetTargetTenantsByRecordType,
    GetTargetTenantsByRecordTypeVariables,
    IFindRecordTypeSummarize,
    TargetTenant
} from 'ui-component/records/types';
import { IGetUsers, IUser } from 'views/backoffice/users/types';

export interface useMyDropdownsProps {
    recordTypeId?: number;
    dropdowns?: ('PROBLEM_CODE' | 'RECORD_STATUS' | 'USER_LIST' | 'TARGET_TENANTS')[];
    onCompleted?: (data: {
        problemCodeList?: IProblemCode[];
        recordStatusList: IRecordStatus[];
        usersList: IUser[];
        targetTenantList?: TargetTenant[];
    }) => void;
}

const useMyDropdowns = ({ recordTypeId, onCompleted, dropdowns }: useMyDropdownsProps) => {
    const localDropdowns = useRef<string[]>(['PROBLEM_CODE', 'RECORD_STATUS', 'USER_LIST', 'TARGET_TENANTS']);
    // const [usersList, setusersList] = useState([]);
    const [problemCodeList, setProblemCodeList] = useState<IProblemCode[]>([]);
    const [recordStatusList, setRecordStatusList] = useState<IRecordStatus[]>([]);
    const [targetTenantList, setTargetTenantList] = useState<TargetTenant[]>([]);
    const [getMainRecordType, { loading: loadingMainRecordType }] = useLazyQuery<IFindRecordTypeSummarize>(QUERY_FIND_RECORD_TYPE, {
        fetchPolicy: 'no-cache'
    });
    const [getUsers, { data: usersData, loading: loadingUsers }] = useLazyQuery<IGetUsers>(QUERY_GET_USERS);
    const [getProblemCode, { loading: loadingProblemCode }] = useLazyQuery<IFindProblemCodeByRecordType>(QUERY_GET_PROBLEM_CODE, {
        fetchPolicy: 'no-cache'
    });
    const [getRecordStatus, { loading: loadingRecordStatus }] = useLazyQuery<IFindRecordStatusByRecordType>(QUERY_FIND_RECORD_STATUS, {
        fetchPolicy: 'no-cache'
    });
    const [getTargetTenants, { loading: loadingTargetTenants }] = useLazyQuery<
        GetTargetTenantsByRecordType,
        GetTargetTenantsByRecordTypeVariables
    >(QUERY_FIND_TARGET_TENANTS, {
        fetchPolicy: 'no-cache'
    });

    const loadDropdowns = useCallback(
        async (recordTypeIdParam: number) => {
            const recordTypesIds = [recordTypeIdParam];
            const { data: mainRecordTypeData } = await getMainRecordType({ variables: { data: { id: recordTypeIdParam } } });
            if (mainRecordTypeData?.findRecordType && mainRecordTypeData.findRecordType[0].isSuper) {
                mainRecordTypeData.findRecordType[0].relatedRecordTypes.forEach((recordType) => {
                    recordTypesIds.push(+recordType.id);
                });
            }
            if (localDropdowns.current.includes('USER_LIST')) {
                await getUsers();
            }
            for await (const id of recordTypesIds) {
                if (localDropdowns.current.includes('PROBLEM_CODE')) {
                    const { data: problemCodeData } = await getProblemCode({
                        variables: { data: { recordType: Number(id) } }
                    });
                    if (problemCodeData?.findProblemCodeByRecordType) {
                        setProblemCodeList((prev) =>
                            [...prev, ...problemCodeData.findProblemCodeByRecordType].sort((a, b) =>
                                a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                            )
                        );
                    }
                }
                if (localDropdowns.current.includes('RECORD_STATUS')) {
                    const { data: recordStatusData } = await getRecordStatus({
                        variables: { data: { recordTypes: [Number(id)] } }
                    });
                    if (recordStatusData?.findRecordStatusByRecordType) {
                        setRecordStatusList((prev) =>
                            [...prev, ...recordStatusData.findRecordStatusByRecordType].sort((a, b) =>
                                a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                            )
                        );
                    }
                }
                if (localDropdowns.current.includes('TARGET_TENANTS')) {
                    const { data: tenantTransmissionData } = await getTargetTenants({
                        variables: { data: { recordTypeId: Number(id) } }
                    });
                    if (tenantTransmissionData?.getTargetTenantsByRecordType) {
                        setTargetTenantList((prev) =>
                            [...prev, ...tenantTransmissionData.getTargetTenantsByRecordType].sort((a, b) =>
                                a.targetTenantToTransmit.name.toLowerCase().localeCompare(b.targetTenantToTransmit.name.toLowerCase())
                            )
                        );
                    }
                }
            }
        },
        [getMainRecordType, getProblemCode, getRecordStatus, getTargetTenants, getUsers]
    );

    const usersList = useMemo(
        () =>
            usersData?.getSaasUsers
                ? [...usersData?.getSaasUsers].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                : [],
        [usersData]
    );

    const loadingLists = loadingMainRecordType || loadingProblemCode || loadingRecordStatus || loadingUsers || loadingTargetTenants;

    useEffect(() => {
        if (!loadingLists && onCompleted) {
            onCompleted?.({ recordStatusList, usersList, problemCodeList });
        }
    }, [loadingLists, onCompleted, problemCodeList, recordStatusList, usersList]);

    useEffect(() => {
        if (dropdowns) localDropdowns.current = dropdowns;
    }, [dropdowns]);

    useEffect(() => {
        if (localDropdowns.current.includes('USER_LIST')) getUsers();
        if (recordTypeId) loadDropdowns(recordTypeId);
    }, [getProblemCode, getRecordStatus, getTargetTenants, getUsers, loadDropdowns, recordTypeId]);

    return { loading: loadingLists, problemCodeList, recordStatusList, usersList, targetTenantList };
};

export default useMyDropdowns;
