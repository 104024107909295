import { Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FilterPanelPropsOverrides, GridFilterModel, GridFilterPanel } from '@mui/x-data-grid-pro';
import { GridFilterPanelProps } from '@mui/x-data-grid/components/panel/filterPanel/GridFilterPanel';

export interface FilterPanelProps extends Partial<GridFilterPanelProps & FilterPanelPropsOverrides> {
    isApplied: boolean;
    isLoading: boolean;
    disableApply: boolean;
    onClickApply: () => void;
    filterModel?: GridFilterModel;
}

export const FilterPanel = ({ isApplied, isLoading, disableApply, onClickApply, filterModel }: FilterPanelProps) => (
    <Box data-testid="custom-filter-panel" sx={{ display: isLoading ? 'none' : 'block' }}>
        <GridFilterPanel columnsSort="asc" />
        {!!filterModel?.items.length && (
            <Box sx={{ px: 2, pb: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center', minHeight: '61px' }}>
                {isApplied ? (
                    <Typography data-testid="applied-filter-text" variant="caption">
                        ✓ Filters Applied
                    </Typography>
                ) : (
                    <LoadingButton
                        data-testid="apply-filter-btn"
                        loading={isLoading}
                        onClick={onClickApply}
                        disabled={disableApply}
                        variant="contained"
                        color="secondary"
                    >
                        Apply
                    </LoadingButton>
                )}
            </Box>
        )}
    </Box>
);
