import { Box } from '@mui/material';
import LogoSection from 'layout/MainLayout/LogoSection';

const SidebarLogo = () => (
    <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
            <LogoSection />
        </Box>
    </Box>
);

export default SidebarLogo;
