import { RegisterRecordVariables } from './types';
import * as yup from 'yup';

export const getYesterdayDate = () => {
    const yesterday = new Date();
    yesterday.setDate(new Date().getDate() - 1);
    return yesterday;
};

export const defaultValues: Partial<RegisterRecordVariables> = {
    tenant: 1,
    resultItemId: '',
    validationRequired: false,
    recordDate: getYesterdayDate().toISOString(),
    description: '',
    poNumber: '',
    recordNumber: '',
    enabled: true
};

export const schema = yup
    .object({
        recordType: yup.number().required('Record type is a required field'),
        recordNumber: yup.string().typeError('Record number have to be a valid number').required('Record number is a required field'),
        description: yup.string().required('Description is a required field'),
        issuedByCompanyName: yup.string().required('Issued by Company Name is a required field'),
        issuedToCompanyName: yup.string().required('Issued To Company Name is a required field'),
        status: yup.number().typeError('Record status is required').required('Status is a required field'),
        poNumber: yup.string().typeError('PO Number have to be a valid number').required('PO Number is a required field'),
        problemCode: yup.number().typeError('Record status is required').required('Problem Code is a required field'),
        recordDate: yup.date().max(getYesterdayDate(), 'Record Date must be earlier than today')
    })
    .required();

/**
 *
 * @deprecated
 * Now should use getDataToCreate or getDataToUpdate
 */
export const formatData = (
    data: Partial<RegisterRecordVariables>,
    tenant: number,
    userId: number,
    recordFileId: number
): RegisterRecordVariables => {
    const newData: RegisterRecordVariables = {
        ...data,
        recordType: Number(data.recordType),
        statusId: Number(data.status),
        problemCodeId: Number(data.problemCode),
        createdBy: userId,
        receivedBy: userId,
        approvedBy: userId,
        poNumber: data.poNumber || '',
        problemCode: data.problemCode || '',
        status: data.status || '',
        description: data.description || '',
        recordDate: data.recordDate || '',
        receivedAt: data.recordDate || '',
        dueDate: new Date().toISOString(),
        recordCreateDate: data.recordDate || '',
        recordNumber: data.recordNumber || '',
        issuedByCompanyName: data.issuedByCompanyName || '',
        issuedToCompanyName: data.issuedToCompanyName || '',
        resultItemId: '',
        assignedTo: Number(data.assignedTo) || undefined,
        enabled: true,
        recordFileId,
        tenant
    };
    return newData;
};
