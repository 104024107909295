import { GridColDef } from '@mui/x-data-grid-pro';
import { ILineItems, ILineItemsFields } from './types';
import { IRecordField, IRecordFieldsList } from '../types';

/**
 *  Generate rows for Line item Grid
 *
 * @param lineItemFields {ILineItemsFields[]} Line item fields
 * @param lineItems {ILineItems[]} Line item values
 */
export const generateLineItemRows = (lineItemFields?: ILineItemsFields[], lineItems?: ILineItems[]) => {
    if (!lineItemFields) return [];
    const arr: any[] = [];

    if (lineItems) {
        for (const item of lineItems) {
            const [, , fieldId] = item.tag.split(';');
            const headerObj = getFieldById(fieldId, lineItemFields);
            if (headerObj) {
                if (arr[item.index - 1] && !arr[item.index - 1]?.autogenerate)
                    arr[item.index - 1][`${headerObj.recordAdditionalFields.name}-${headerObj.order}`] = item.value;
                else arr[item.index - 1] = { [`${headerObj.recordAdditionalFields.name}-${headerObj.order}`]: item.value, id: item.index };
            }
        }
    }
    return arr.filter((el) => el !== undefined || el !== null);
};

export const generateLineItemRow = (headersDef: GridColDef[]) => {
    const arr: any[] = [];

    const filledArr = fillLineItemArr(arr, headersDef);

    return filledArr;
};

/**
 *  Return the line item field ILineItemsFields for given id or undefined
 *
 * @param fieldId {string} field id of Line item
 * @param lineItemHeaders {ILineItemsFields[]} Array of fields of the lineItem configuration
 */
export const getFieldById = (fieldId: string, lineItemHeaders: ILineItemsFields[]) =>
    lineItemHeaders.find((el) => Number(el.recordAdditionalFields.id) === Number(fieldId));

/**
 * Fill the given array or empty spaces in the array with empty line item rows
 *
 * @param arr {(Object | undefined)[]} Array of line item rows generated
 * @param headersDef {GridColDef[]} Definitions for columns
 */
export const fillLineItemArr = (arr: (Record<string, string | number> | undefined)[], headersDef: GridColDef[]) => {
    if (!arr.length) return generateEmptyLineItemRows(headersDef);

    const limit = arr.length > 5 ? arr.length : 5;

    const filledArr: Record<string, any>[] = [];
    for (let idx = 0; idx < limit; idx += 1) {
        filledArr[idx] = arr[idx] || generateEmptyLineItemRows(headersDef, 1, idx)[0];
    }

    return filledArr;
};

/**
 * Generate empty Line Item rows based on columns
 *
 * @param headersDef {GridColDef[]} Grid definition for columns
 * @param quantity {Number} Quantity of empty line item rows to create
 * @param startId {Number} Starting Id for the new empty line item rows
 */
export const generateEmptyLineItemRows = (headersDef: GridColDef[], quantity: number = 5, startId: number = 0) =>
    [...Array(quantity).keys()].map((el) => ({
        id: startId + el,
        autogenerate: true,
        ...headersDef.reduce((acc, header) => ({ ...acc, [header.field]: '' }), {})
    }));

/**
 * Returns value from the stored line item data
 *
 * @param index {Object} The index for the line item, normally is the id of the newRow from the grid
 * @param lineItemsData {ILineItems{}} The raw data of the line items
 * @param headers {ILineItemsFields[]} Headers for line items
 * @param key {String} key
 */
export const getValueFromLineItemsData = (
    index: Record<string, any>,
    lineItemsData?: ILineItems[],
    headers?: ILineItemsFields[],
    key?: string
) => {
    if (!lineItemsData || !headers || !key) return undefined;

    const [fieldNameFromUpdated, fieldOrder] = key.split('-');

    return lineItemsData.find((el) => {
        const [, , fieldId] = el.tag.split(';');

        const idFromAditionalField = headers.find(
            (typeField) => typeField.recordAdditionalFields.name === fieldNameFromUpdated && typeField.order === Number(fieldOrder)
        )?.recordAdditionalFields.id;

        return Number(el.index) === Number(index) && Number(fieldId) === Number(idFromAditionalField);
    });
};

/**
 * Return the line item fields with the same shape as common fields
 *
 * @param lineItemHeaders {ILIneItemsFields}
 */
export const getFieldsAsHeadersFields = (lineItemHeaders?: ILineItemsFields[]) => {
    if (!lineItemHeaders || !lineItemHeaders.length) return {};
    return lineItemHeaders
        .map((el) => el.recordAdditionalFields)
        .reduce<Partial<IRecordFieldsList>>((acc, el) => ({ ...acc, [el.name]: el as unknown as IRecordField }), {});
};
