import * as CognitoSDK from '@aws-sdk/client-cognito-identity-provider';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { AWS_API } from 'config';
import { InitialLoginContextProps } from 'types/auth';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';

export const initialState: InitialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

export const awsAdminClient = new CognitoSDK.CognitoIdentityProviderClient({
    region: AWS_API.region,
    credentials: {
        accessKeyId: AWS_API.accessKeyId,
        secretAccessKey: AWS_API.secretAccessKey
    }
});

export const userPool = new CognitoUserPool({
    UserPoolId: AWS_API.poolId || '',
    ClientId: AWS_API.appClientId || ''
});

export const cleanLocalStorage = () => {
    localStorage.removeItem('backend_jwt');
    localStorage.removeItem('shouldSkipSetupMFA');
    localStorage.removeItem('associateSoftwareToken');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('tenantSelected');
    localStorage.removeItem('tenantPath');
    localStorage.removeItem('tenant_id');
};

/**
 * Get temporary AWS credentials using AWS Cognito Identity Pool.
 * Exchanges the JWT token stored in local storage for temporary AWS credentials.
 * @returns {Promise<{
 *   accessKeyId: string;
 *   secretAccessKey: string;
 *   sessionToken: string;
 *   expiration: Date;
 * }>}
 * @throws Error if there is an error fetching the temporary AWS credentials
 */
export const getTemporaryAWSCredentials = async () => {
    try {
        // Use AWS Cognito Identity Pool to exchange JWT token for temporary AWS credentials
        const credentialsProvider = fromCognitoIdentityPool({
            clientConfig: {
                region: AWS_API.region
            },
            identityPoolId: AWS_API.identityPoolId, // Replace with your Identity Pool ID or pass as parameter
            logins: {
                [`cognito-idp.${AWS_API.region}.amazonaws.com/${AWS_API.poolId}`]: localStorage.getItem('idToken') ?? '' // Set correct User Pool ID
            }
        });

        // Retrieve the credentials
        const credentials = await credentialsProvider();

        // Return credentials
        return {
            accessKeyId: credentials.accessKeyId,
            secretAccessKey: credentials.secretAccessKey,
            sessionToken: credentials.sessionToken,
            expiration: credentials.expiration
        };
    } catch (error) {
        console.error('Error fetching temporary AWS credentials:', error);
        throw error;
    }
};

export const setSession = (serviceToken?: string | null, idToken?: string | null) => {
    if (serviceToken) localStorage.setItem('serviceToken', serviceToken);
    else localStorage.removeItem('serviceToken');
    if (idToken) localStorage.setItem('idToken', idToken);
    else localStorage.removeItem('idToken');
};

export const AUTH_ROUTES = [
    '/login',
    '/register',
    '/forgot',
    '/recover',
    '/reset',
    '/user-confirmation',
    '/change',
    '/configured',
    '/setupmfa',
    '/select-tenant'
] as const;
