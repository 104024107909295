/**
 * Checks if a given string is a valid JSON string.
 *
 * @param {string} str - The input string to be checked.
 * @return {boolean} True if the string is a valid JSON string, false otherwise.
 */
export const isJsonString = (str: string) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

/**
 * Checks if a given string is a valid JSON object string and not null.
 *
 * @param {string} str - The input string to be checked.
 * @return {boolean} True if the string is a valid JSON string and not null, false otherwise.
 */
export const isJsonStringPreventNullAndNonObject = (str: string) => {
    try {
        const val = JSON.parse(str);
        if (val === null) return false;
        if (typeof val !== 'object') return false;
    } catch (e) {
        return false;
    }
    return true;
};
