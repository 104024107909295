import { GridApiPro } from '@mui/x-data-grid-pro';

export const findOnlyCellsWithValues = (obj: Record<string, any>) => {
    let clearedObj: Record<string, any> = {};
    Object.keys(obj).forEach((key) => {
        Object.keys(obj[key]).forEach((childKey) => {
            if (obj[key][childKey].value)
                clearedObj = {
                    ...clearedObj,
                    [key]: {
                        ...clearedObj[key],
                        [childKey]: obj[key][childKey]
                    }
                };
        });
    });
    return clearedObj;
};

/**
 * Return and object with only cells that were edited
 *
 * @param obj {Object} Row edit model
 * @returns
 */
export const findCellsEdited = (apiRef: GridApiPro, obj?: Record<string, any>) => {
    if (!obj) return [];
    const updateObjs: Record<string, any>[] = [];

    Object.entries(obj).forEach(([id, fields]) => {
        const clearedObj: Record<string, any> = { id };
        const currentObj = apiRef.getRow(id);

        for (const key in fields) {
            if (Object.prototype.hasOwnProperty.call(fields, key)) {
                const currentValue = currentObj[key]?.name || currentObj[key];
                const valueObj = apiRef.getRowWithUpdatedValues(id, key);
                if ((valueObj[key] || valueObj[key] === '') && currentValue !== valueObj[key]) {
                    clearedObj[key] = valueObj[key];
                }
            }
        }
        if (Object.keys(clearedObj).length > 1) updateObjs.push(clearedObj);
    });

    return updateObjs;
};

/**
 * @deprecated Now we updated directly the query
 *
 * Returns an array with object with id of the row and
 * fieldName to send it to commitCellChange
 * @param obj {Object} Row edit model
 * @returns
 */
export const generateCommitCellObjs = (obj: Record<string, any>) => {
    const commitObjs: { id: number; field: string }[] = [];

    Object.entries(obj).forEach(([id, fields]) => {
        commitObjs.push(
            ...Object.keys(fields)
                .filter((fieldKey) => Object.prototype.hasOwnProperty.call(fields[fieldKey], 'value'))
                .map((fieldKey) => ({ id: Number(id), field: fieldKey }))
        );
    });

    return commitObjs;
};
