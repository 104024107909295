import { memo } from 'react';

// project imports
import { isPoetProject } from 'utils';
import AppModuleList from './AppModuleList';
import SkeletonList from './SkeletonList';
import BaseModuleList from './BaseModuleList';
import useMyMenuItems from 'hooks/useMyMenuItems';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const { loading, filteredList } = useMyMenuItems();

    if (loading) return <SkeletonList />;

    return (
        <div style={{ marginTop: 10, ...(isPoetProject() ? { flex: 1 } : {}) }}>
            <BaseModuleList />
            <AppModuleList filteredMenuData={filteredList || []} />
        </div>
    );
};

export default memo(MenuList);
