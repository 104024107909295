import { Box, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { getDateWithoutTZ } from 'ui-component/records/utils/dateHelpers';

export interface DateEditCellProps {
    onChange: (value: string) => void;
    field: string;
    value?: string;
}

export const DateEditCell = ({ onChange, field, value, hideLabel }: DateEditCellProps & { hideLabel?: boolean }) => {
    const [dateValue, setDateValue] = useState(
        value && String(getDateWithoutTZ(value)) !== 'Invalid Date' ? format(getDateWithoutTZ(value), 'yyyy-MM-dd') : null
    );

    useEffect(() => {
        value && setDateValue(value);
    }, [value]);

    const handleChange = async (newValue: string | null) => {
        if (newValue === undefined) return;
        setDateValue(newValue);
        onChange(newValue ?? '');
    };

    return (
        <Box sx={{ minWidth: 149 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                <DatePicker
                    label={!hideLabel && field}
                    value={dateValue}
                    inputFormat="MM/DD/YYYY"
                    onChange={handleChange}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
        </Box>
    );
};
